import cigLogo from "./CIGLogo.png";
import bodySelectImage from "./bodySelect.png";
import frontFaceImage from "./frontFace.png";
import torsoSelectImage from "./torsoSelect.png";

const logos: Logo = {
  "CIGLogo.png": cigLogo,
  "bodySelect.png": bodySelectImage,
  "frontFace.png": frontFaceImage,
  "torsoSelect.png": torsoSelectImage
};

export default logos;
