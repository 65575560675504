export const isImageSelectResponse = (x: unknown): x is ImageSelectResponse => {
  return (
    (x as ImageSelectResponse).imageFileName !== undefined &&
    (x as ImageSelectResponse).imageURL !== undefined &&
    (x as ImageSelectResponse).imageSelections !== undefined
  );
};

export const isTextValueObject = (x: unknown): x is TextValueObject => {
  return (x as TextValueObject).text !== undefined && (x as TextValueObject).value !== undefined;
};
