import React from "react";
import cx from "classnames";

import AnimationPlayer, { AnimationVariant } from "../AnimationPlayer";

import styles from "./index.module.scss";

type PropsType = {
  numberOfTasks: number;
  numberOfCompleteTasks: number;
};

const maxAnimationDuration = 5.2;
const minAnimationDuration = 2.2;
const minFrame = 0;
const lastFrame = 180;
const finalRunningFrame = 125;

const ProgressBar = ({ numberOfTasks, numberOfCompleteTasks }: PropsType) => {
  const percentComplete = numberOfCompleteTasks / numberOfTasks;
  const percentBonusProgress = percentComplete === 0 ? 0.09 : 0; // whenever there is 0 tasks completed, we display a 9% progress
  const progressBarWidth = Math.floor((percentComplete + percentBonusProgress) * 100);

  const getAnimationDetails = () => {
    const allTasksCompleted = numberOfCompleteTasks >= numberOfTasks;
    const framesPerSecond = 25;
    const animationDuration = Math.floor(
      (maxAnimationDuration - minAnimationDuration) * (percentComplete + percentBonusProgress) +
        minAnimationDuration
    );

    const endFrame = allTasksCompleted ? lastFrame : finalRunningFrame;
    const startFrame =
      Math.floor(endFrame - animationDuration * framesPerSecond) < minFrame
        ? 0
        : Math.min(Math.floor(endFrame - animationDuration * framesPerSecond), finalRunningFrame);

    const segments = [startFrame, endFrame];

    return { segments, animationDuration };
  };

  const { segments, animationDuration } = getAnimationDetails();

  return (
    <div className={cx(styles.ProgressBarWrapper)}>
      <div className={cx(styles.ProgressBar)} style={{ width: `${progressBarWidth}%` }}>
        <div
          className={cx(styles.ProgressLine, {
            [styles.ProgressLineFast]: percentComplete < 0.5
          })}
          style={{ animationDuration: `${animationDuration}s` }}
        />
        <div className={cx(styles.Mika)}>
          <AnimationPlayer
            variant={AnimationVariant.MIKA_PROGRESS_CHARACTER}
            configurationOptions={{
              loop: false,
              autoplay: true,
              segments
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
