// Status definitions:
// https://docs.google.com/spreadsheets/d/13EuqrCl3Ox4hWiBB6ny9LqF6aYeKMUNgyxObjLuIx1U/edit?folder=1dHH3EkGRcObwp_NnCgcmOexWcZaAKx4Q#gid=0
export const ROOT_URL = process.env.REACT_APP_API_URL;
export const STAGING_URL = "https://app.staging.mikatahealth.com";
export const MAIN_URL = "https://app.mikatahealth.com";

export const TOGGLE_UPLOAD = "TOGGLE_UPLOAD";
export const SHOW_UPLOAD = "SHOW_UPLOAD";
export const HIDE_UPLOAD = "HIDE_UPLOAD";
export const SHOW_SAMPLE_UPLOAD = "SHOW_SAMPLE_UPLOAD";
export const HIDE_SAMPLE_UPLOAD = "HIDE_SAMPLE_UPLOAD";
export const SHOW_CONFIRM_DISABLE_UPLOADS = "SHOW_CONFIRM_DISABLE_UPLOADS";
export const HIDE_CONFIRM_DISABLE_UPLOADS = "HIDE_CONFIRM_DISABLE_UPLOADS";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const SHOW_CHECKLIST = "SHOW_CHECKLIST";
export const HIDE_CHECKLIST = "HIDE_CHECKLIST";
export const SHOW_PROCEDURE_GUIDE = "SHOW_PROCEDURE_GUIDE";
export const HIDE_PROCEDURE_GUIDE = "HIDE_PROCEDURE_GUIDE";
export const REPORT_MODAL_OPEN = "REPORT_MODAL_OPEN";
export const REPORT_MODAL_CLOSED = "REPORT_MODAL_CLOSED";
export const REPORT_MODAL_APPT_ID = "REPORT_MODAL_APPT_ID";
export const CREATE_REPORT_SNAPSHOT = "CREATE_REPORT_SNAPSHOT";
export const CLEAR_REPORT_SNAPSHOT = "CLEAR_REPORT_SNAPSHOT";

export const FETCH_RESCHED_APPTS = "FETCH_RESCHED_APPTS";
export const FETCH_CONFIRMED_APPTS = "FETCH_CONFIRMED_APPTS";
export const FETCH_APPTS_NEED_CONFIRM = "FETCH_APPTS_NEED_CONFIRM";
export const FETCH_APPTS_FOR_DAY = "FETCH_APPTS_FOR_DAY";
export const FETCH_APPTS_NO_RESP = "FETCH_APPTS_NO_RESP";
export const UPDATE_APPT_COMPLETE = "UPDATE_APPT_COMPLETE";
export const APPOINTMENTS = "APPOINTMENTS";
export const APPOINTMENT_REQUESTS = "APPOINTMENT_REQUESTS";
export const UPDATE_APPOINTMENT_REQUEST = "UPDATE_APPOINTMENT_REQUEST";
export const UPDATE_APPT_ROW = "UPDATE_APPT_ROW";
export const UPDATE_SINGLE_APPT = "UPDATE_SINGLE_APPT";
export const FETCH_DATA_FOR_MIKATA_ADMIN = "FETCH_DATA_FOR_MIKATA_ADMIN";
export const FETCH_DATA_FOR_ORGANIZATION = "FETCH_DATA_FOR_ORGANIZATION";
export const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const SEND_CAMPAIGN = "SEND_CAMPAIGN";
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const FETCH_UPLOAD_RECORD = "FETCH_UPLOAD_RECORD";
export const GET_MISSED_UPLOAD = "GET_MISSED_UPLOAD";
export const GET_LATEST_REMINDER_TIME = "GET_LATEST_REMINDER_TIME";
export const FETCH_CHAT_MESSAGE_INFO = "FETCH_CHAT_MESSAGE_INFO";
export const FETCH_CHAT_INFO = "FETCH_CHAT_INFO";
export const INIT_CONVERSATION_HUB = "INIT_CONVERSATION_HUB";
export const SEND_REFRESH_LINK = "SEND_REFRESH_LINK";
export const UPDATE_APPT_INFO = "UPDATE_APPT_INFO";
export const ACTION_ERROR = "ACTION_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

// Chat Messages Actions
export const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES";
export const FETCH_NEXT_MESSAGE = "FETCH_NEXT_MESSAGE";
export const ADD_USER_RESPONSE = "ADD_USER_RESPONSE";
export const UNDO_USER_RESPONSE = "UNDO_USER_RESPONSE";
export const DELETE_SECURE_MESSAGE_ITEM = "DELETE_SECURE_MESSAGE_ITEM";
export const START_CHAT_SESSION = "START_CHAT_SESSION";

// Booking Actions
export const GET_AVAILABLE_APPOINTMENTS = "GET_AVAILABLE_APPOINTMENTS";
export const RESET_AVAILABLE_APPOINTMENTS = "RESET_AVAILABLE_APPOINTMENTS";
export const ADD_AVAILABLE_SLOT = "ADD_AVAILABLE_SLOT";
export const REMOVE_AVAILABLE_SLOT = "REMOVE_AVAILABLE_SLOT";
export const SET_SLOT_SELECTION = "SET_SLOT_SELECTION";
export const AUTO_BOOK_APPOINTMENT = "AUTO_BOOK_APPOINTMENT";

// Recordings Actions
export const GET_APPOINTMENT_RECORDINGS = "GET_APPOINTMENT_RECORDINGS";
export const DELETE_APPOINTMENT_RECORDING = "DELETE_APPOINTMENT_RECORDING";

// Notification Overlay
export const SHOW_NOTIFICATION_OVERLAY = "SHOW_NOTIFICATION_OVERLAY";
export const CLEAR_NOTIFICATION_OVERLAY = "CLEAR_NOTIFICATION_OVERLAY";

export const RESCHEDULE = "RESCHEDULE";
export const CONFIRMED = "CONFIRMED";
export const UNCLEAR = "UNCLEAR";
export const NEED_CONFIRM = "NEED_CONFIRM";
export const NONE = "NONE";

// Colours
export const GREEN = "#00BCBD";
export const LIGHT_GREEN = "#7FDCDC";
export const LIGHT_GREEN_2 = "#8dd7cf";
export const FADED_GREEN = "#95dede";
export const GREY = "#D8D8D8";
export const GREY_OUTLINE = "#9eadba";
export const LIGHT_GREY = "#F4F4F4";
export const RED = "#FF4368";
export const LIGHT_RED = "#e9a2ad";
export const YELLOW = "#FFCC79";
export const BLACK = "#000000";

export const DashboardPages = Object.freeze({
  APPOINTMENTS: "Appointments",
  APPOINTMENT_REQUESTS: "Appointment Requests"
});

export const ChatCardTypes = Object.freeze({
  ADDRESS_INPUT: "addressInput",
  APPT_INFO: "apptInfo",
  APPT: "appt",
  BACK: "back",
  BOOKING: "booking",
  CONTACT: "contact",
  CHECK_IN: "checkInAction",
  DATE_INPUT: "dateInput",
  DETAILED_SELECT: "detailedSelect", // deprecated. See IHA-4724
  DEAD_END: "deadEnd",
  DONE_BUTTON: "doneButton",
  EMAIL_INPUT: "emailInput",
  END: "end",
  FLAGGED_END: "flaggedEnd",
  IMAGE_SELECT: "imageSelect",
  INSTRUCTIONS: "instructions",
  INSTRUCTION_REASONS: "instructionReasons",
  INSTRUCTIONS_FROM_REASONS: "instructionsFromReasons",
  LANDING: "landing",
  MULTI_SELECT: "multiSelect",
  NEXT_BUTTON: "nextButton",
  NUMBER_INPUT: "numberInput",
  PATIENT_VERIFICATION: "patientVerification",
  PHONE_INPUT: "phoneInput",
  PHOTO_UPLOAD: "photoUpload",
  PROCEDURE_INFO_NEW: "procedureInfoNew", // deprecated
  PROCEDURE_INFO: "procedureInfo", // deprecated. See IHA-298 and IHA-380
  SCALE_NUMBERS: "scaleNumbers", // deprecated. See IHA-4908
  SCALE_INPUT: "scaleInput",
  SCRIBE_VISIT: "scribeVisit",
  SECURE_MESSAGE: "secureMessage",
  SINGLE_SELECT: "singleSelect",
  SUMMARY: "summary",
  TEXT_INPUT: "textInput",
  TEXT: "text",
  UNITS_INPUT: "unitsInput",
  USER_RESPONSE: "userResponse"
});

export const ReportCardTypes = Object.freeze({
  TITLE: "title",
  HEADER: "header",
  SUBHEADER_1: "subHeader1", // underline large
  SUBHEADER_2: "subHeader2", // underline normal
  SUBHEADER_3: "subHeader3", // bold normal
  TEXT: "text",
  TEXT_2: "text2", // half padding
  TEXT_3: "text3", // no padding
  TRANSCRIPT: "transcript",
  TABLE: "table",
  IMAGESELECT: "imageSelect"
});

export const ChatCardSubTypes = Object.freeze({
  TEXT: "text",
  DATE: "date",
  NUMBER: "number",
  RANGE: "range",
  PAIN_SCALE: "painScale"
});

export const AppointmentStates = Object.freeze({
  RESCHEDULE: "RESCHEDULE",
  CONFIRMED: "CONFIRMED",
  UNCLEAR: "UNCLEAR",
  NEED_CONFIRM: "NEED_CONFIRM",
  NEEDS_CALL: "NEEDS_CALL",
  NONE: "NONE",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
  CONTACTED: "CONTACTED",
  ON_HOLD: "ON_HOLD"
});

export const ConversationStatuses = Object.freeze({
  NOT_APPLICABLE: "Not applicable",
  NOT_STARTED: "Not started",
  NEEDS_HELP: "Needs help",
  WRONG_PROCEDURE: "Wrong Procedure", // Deprecated
  IN_PROGRESS: "In progress",
  ENTERED_GUIDE: "Entered Guide", // Deprecated
  COMPLETED_GUIDE: "Completed Guide", // Deprecated
  ABANDONED: "Abandoned", // Deprecated
  COMPLETE: "Complete",
  FLAGGED: "Flagged"
});

export const AppointmentPrepStatuses = Object.freeze({
  NOT_OPENED: "not opened",
  ENTERED_CHAT: "entered chat",
  OPENED: "opened",
  CONFUSED: "confused",
  ENTERED_GUIDE: "entered guide",
  SEEN_GUIDE: "seen guide",
  NOT_APPLICABLE: "not applicable"
});

export const FETCH_INSTRUCTIONS_INFO = "FETCH_INSTRUCTIONS_INFO";

export const UploadRecordStatuses = Object.freeze({
  PROCESSING: "processing",
  SUCCESS: "success",
  VERIFIED: "verified",
  VERIFYING: "verifying",
  PROBLEM_SENDING: "error problem sending reminders",
  NO_APPTS_FOUND: "error no appts found",
  BAD_FILE: "bad file",
  DUPLICATE: "duplicate"
});

export const AppointmentRequestStatuses = Object.freeze({
  IN_PROGRESS: "In progress",
  IN_PROGRESS_WAITING_ON_INPUT: "In progress: Waiting on input",
  READY: "Ready",
  BOOKED: "Booked",
  UNABLE_TO_BOOK_INSUFFICIENT_INFO: "Unable to book: Insufficient info",
  UNABLE_TO_BOOK_NOT_A_REQUEST: "Unable to book: Not a request"
});

export const AppointmentRequestFilters = Object.freeze({
  REQUESTS: "Requests",
  ARCHIVE: "Archive"
});

export const AppointmentRequestColumnSort = Object.freeze({
  CREATED_AT: "created_at",
  PATIENT_NAME: "patientName",
  PATIENT_NUMBER: "patientNumber",
  MOBILE: "mobileFirst",
  REASON: "reasons",
  LOCATION_PREFERENCE: "locationPreference",
  DATETIME_PREFERENCE: "dateTimePreference",
  BOOKING_STATUS: "status",
  REQUISITION: "hasRequisition"
});

export const UserTypeConstants = Object.freeze({
  MIKATA_ADMIN: "mikata admin",
  PATIENT: "patient",
  STAFF: "staff",
  PRACTITIONER: "practitioner"
});

export const StatusFilterMapping = Object.freeze({
  noReminderApptsCount: [AppointmentStates.NEED_CONFIRM, AppointmentStates.NEEDS_CALL],
  confirmedApptsCount: [AppointmentStates.CONFIRMED, AppointmentStates.CONTACTED],
  rescheduleApptsCount: [AppointmentStates.RESCHEDULE],
  noResponseApptsCount: [
    AppointmentStates.NONE,
    AppointmentStates.UNCLEAR,
    AppointmentStates.PENDING
  ]
});

export const ChatFlowTitles = Object.freeze({
  FEEDBACK: "Feedback",
  PREVISIT_INSTRUCTIONS: "previsit instructions",
  PREVISIT_INSTRUCTIONS_MULTI: "previsit instructions multi",
  GP_HEALTH_CHAT: "GP Health Chat"
});

export const ChatFlowTags = Object.freeze({
  PREVISIT: "Previsit",
  POSTVISIT: "postvisit",
  APPOINTMENT_REQUEST: "Appointment Request",
  ASSESSMENT: "Assessment",
  APPOINTMENT_DETAILS: "Appointment Details",
  USE_GRAPH_SYSTEM: "useGraphSystem",
  SECURE_DIRECT_MESSAGE: "Secure Direct Message",
  HIDE_APPOINTMENT_TIMES: "hideAppointmentTimes"
});

export const timezones = [
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa"
];

export const AccuroURLs = Object.freeze({
  OFFICES: "https://q1-accapi.accuroemr.com/rest/v1/offices",
  LOCATIONS: "https://q1-accapi.accuroemr.com/rest/v1/locations",
  PRACTITIONERS: "https://q1-accapi.accuroemr.com/rest/v1/providers/offices",
  REASONS: "https://q1-accapi.accuroemr.com/rest/v1/scheduler/reasons",
  STATUSES: "https://q1-accapi.accuroemr.com/rest/v1/scheduler/statuses",
  APPOINTMENTS: "https://q1-accapi.accuroemr.com/rest/v2/materials/appointments",
  APPOINTMENT: "https://q1-accapi.accuroemr.com/rest/v1/provider-portal/scheduler/appointments",
  LETTERS: "https://q1-accapi.accuroemr.com/rest/v1/provider-portal/letters"
});

export const SettingNames = Object.freeze({
  CHECK_SENT_BOOKED: "checkSentBooked",
  ACCURO_STATUS_MAP: "accuroStatusMap",
  REPORT_MAPPING: "reportMapping"
});

export const OptionsRestrictedToOne = Object.freeze([
  "None of the above.",
  "None of the above",
  "None.",
  "None",
  "I'm not sure"
]);

export const BucketNameSuffixes = Object.freeze({
  ACCURO_FILE_SYNC: "sync-files",
  PATIENT_UPLOADS: "patient-uploads",
  DATA_SCI_RAW_DATA: "data-sci-raw-data",
  FILE_UPLOADS: "file-uploads",
  ORG_SAMPLE_EXPORT: "org-sample-export",
  CAMPAIGN_UPLOADS: "campaign-uploads"
});

export const NotificationTypes = Object.freeze({
  ACCESS_BLOCKED: "accessBlocked",
  GENERIC_APPLICATION_ERROR: "genericApplicationError",
  SECURE_MESSAGE_EXPIRED: "secureMessageExpired",
  SESSION_EXPIRED: "sessionExpired",
  LINK_EXPIRED: "linkExpired",
  LINK_EXPIRED_CAN_REFRESH: "linkExpiredCanRefresh",
  LINK_RESENT: "linkResent"
});

export const StartCardVariants = Object.freeze({
  CARD: "card",
  TASK: "task",
  PRIORITY_TASK: "priorityTask"
});
