import axios from "axios";
import { ROOT_URL } from "../constants";

export const updateUserResponse = async (
  chatFlowMessageId: number,
  newResponse: string | string[],
  sessionId: number,
  apptRequestField: boolean,
  token: string
) => {
  // TODO:remove "token" function argument
  const sessionToken = sessionStorage.getItem("token");

  const config = {
    headers: { Authorization: sessionToken, "Content-Type": "application/json" }
  };

  try {
    const response = await axios.patch(
      `${ROOT_URL}/chat-flows/messages/${chatFlowMessageId}`,
      { newResponse, sessionId, apptRequestField },
      config
    );
    const { data } = response;
    return data;
  } catch (e) {
    return { error: e };
  }
};
