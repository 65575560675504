/* Avatar
======================================= */
import React from "react";
import { connect } from "react-redux";

import styles from "./index.module.scss";

type PropsType = {
  avatar?: string;
  organizationName?: string;
  size?: number;
  children?: React.ReactNode;
};

const Avatar = ({ size = 32, avatar, organizationName, children }: PropsType) => {
  return (
    <div className={styles.Avatar} style={{ width: `${size}px`, height: `${size}px` }}>
      {avatar && avatar !== "" ? (
        <img src={`${avatar}`} alt={`${organizationName || ""} avatar`} />
      ) : (
        children
      )}
    </div>
  );
};

const mapStateToProps = ({ chatInfo }: ReduxState) => {
  return {
    avatar: chatInfo?.appearanceSettings?.settingValue?.avatar,
    organizationName: chatInfo?.organizationName
  };
};

export default connect(mapStateToProps, undefined)(Avatar);
