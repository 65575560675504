import { FETCH_CHAT_MESSAGE_INFO } from "../constants";
import { SetChatMessageInfoAction } from "../types/actions";

const initialState: ChatMessageInfo = {
  chatFlowId: 0,
  chatFlowMessageId: 0,
  message: {
    id: 0,
    subject: null,
    topic: "",
    organizationId: 0,
    locationId: null,
    sendDaysBefore: null,
    payloadContent: null,
    payloadAttachmentUrl: null,
    payloadOptions: null,
    medium: "",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    expectsResponse: false,
    cardType: "",
    sender: "",
    sent7Day: null,
    sentWith2Day: null,
    practitionerId: null,
    subType: null,
    reportFieldLabel: null,
    chatFlowMessageId: 0
  },
  breadcrumb: []
};

export const chatMessageInfoReducer = (
  state = initialState,
  action: SetChatMessageInfoAction
): ChatMessageInfo => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CHAT_MESSAGE_INFO:
      return payload && payload.data ? { ...state, ...payload.data } : state;
    default:
      return state;
  }
};
