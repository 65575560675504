import React from "react";

type PropsType = {
  size?: number;
};

const User = ({ size = 20 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10C7.69881 10 5.83333 8.13452 5.83333 5.83334C5.83333 3.53215 7.69881 1.66667 10 1.66667C12.3012 1.66667 14.1667 3.53215 14.1667 5.83334C14.1667 8.13452 12.3012 10 10 10ZM17.5 17.5V15.8333C17.5 13.5322 15.6345 11.6667 13.3333 11.6667H6.66667C4.36548 11.6667 2.5 13.5322 2.5 15.8333V17.5C2.5 17.9602 2.8731 18.3333 3.33333 18.3333C3.79357 18.3333 4.16667 17.9602 4.16667 17.5V15.8333C4.16667 14.4526 5.28595 13.3333 6.66667 13.3333H13.3333C14.714 13.3333 15.8333 14.4526 15.8333 15.8333V17.5C15.8333 17.9602 16.2064 18.3333 16.6667 18.3333C17.1269 18.3333 17.5 17.9602 17.5 17.5ZM12.5 5.83334C12.5 7.21405 11.3807 8.33334 10 8.33334C8.61929 8.33334 7.5 7.21405 7.5 5.83334C7.5 4.45263 8.61929 3.33334 10 3.33334C11.3807 3.33334 12.5 4.45263 12.5 5.83334Z"
        fill="#1D2029"
      />
      <mask id="mask0_2715_4398" maskUnits="userSpaceOnUse" x="2" y="1" width="16" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 10C7.69881 10 5.83333 8.13452 5.83333 5.83334C5.83333 3.53215 7.69881 1.66667 10 1.66667C12.3012 1.66667 14.1667 3.53215 14.1667 5.83334C14.1667 8.13452 12.3012 10 10 10ZM17.5 17.5V15.8333C17.5 13.5322 15.6345 11.6667 13.3333 11.6667H6.66667C4.36548 11.6667 2.5 13.5322 2.5 15.8333V17.5C2.5 17.9602 2.8731 18.3333 3.33333 18.3333C3.79357 18.3333 4.16667 17.9602 4.16667 17.5V15.8333C4.16667 14.4526 5.28595 13.3333 6.66667 13.3333H13.3333C14.714 13.3333 15.8333 14.4526 15.8333 15.8333V17.5C15.8333 17.9602 16.2064 18.3333 16.6667 18.3333C17.1269 18.3333 17.5 17.9602 17.5 17.5ZM12.5 5.83334C12.5 7.21405 11.3807 8.33334 10 8.33334C8.61929 8.33334 7.5 7.21405 7.5 5.83334C7.5 4.45263 8.61929 3.33334 10 3.33334C11.3807 3.33334 12.5 4.45263 12.5 5.83334Z"
          fill="white"
        />
      </mask>
    </svg>
  );
};

export default User;
