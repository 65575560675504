import { FETCH_INSTRUCTIONS_INFO } from "../constants";
import { SetInstructionsAction } from "../types/actions";

const initialState: ChecklistInfoStore = {
  checklistsById: {}
};

export const checklistInfoReducer = (
  state = initialState,
  action: SetInstructionsAction
): ChecklistInfoStore => {
  const { type, payload } = action;

  const data: InstructionsPayload["data"] | null = payload && payload.data ? payload.data : null;

  switch (type) {
    case FETCH_INSTRUCTIONS_INFO:
      if (!data) return state;

      return {
        ...state,
        checklistsById: {
          ...state.checklistsById,
          [data.checklistSelected]: {
            content: data.checklist.content || []
          }
        }
      };
    default:
      return state;
  }
};
