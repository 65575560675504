import {
  FETCH_CHAT_INFO,
  FETCH_CHAT_MESSAGE_INFO,
  FETCH_INSTRUCTIONS_INFO,
  SHOW_PROCEDURE_GUIDE,
  HIDE_PROCEDURE_GUIDE,
  GET_AVAILABLE_APPOINTMENTS,
  RESET_AVAILABLE_APPOINTMENTS,
  ADD_AVAILABLE_SLOT,
  REMOVE_AVAILABLE_SLOT,
  GET_APPOINTMENT_RECORDINGS,
  DELETE_APPOINTMENT_RECORDING,
  SHOW_NOTIFICATION_OVERLAY,
  CLEAR_NOTIFICATION_OVERLAY,
  SET_SLOT_SELECTION,
  AUTO_BOOK_APPOINTMENT,
  SEND_REFRESH_LINK,
  AppointmentStates
} from "../constants";

import { NotificationMetaDictionary, NotificationType } from "./notifications";

import { ChatMessagesAction } from "../reducers/chatMessagesReducer";
export interface FetchChatInfoAction {
  type: typeof FETCH_CHAT_INFO;
  payload?: ChatInfoPayload;
}
export interface UpdateChatInfoAction {
  type: string;
  status: ActionStatus;
  payload: {
    appointmentStatus?: typeof AppointmentStates.CONFIRMED | typeof AppointmentStates.RESCHEDULE;
    checkedIn?: boolean;
    success?: boolean;
    error?: boolean;
    data?: {
      appointmentUpdates: {
        id: number;
        patientId: number;
        cancelled: boolean;
        start: Date;
        end: Date;
        status: string;
      }[];
    };
  };
}

export interface InitConversationHubAction {
  type: string;
  payload: ChatInfoPayload;
}

export interface SendRefreshLinkAction {
  type: typeof SEND_REFRESH_LINK;
  status: ActionStatus;
}

export type ChatInfoAction =
  | FetchChatInfoAction
  | UpdateChatInfoAction
  | InitConversationHubAction
  | SendRefreshLinkAction;

export interface SetChatMessageInfoAction {
  type: typeof FETCH_CHAT_MESSAGE_INFO;
  payload: ChatMessageInfoPayload;
}

export interface SetInstructionsAction {
  type: typeof FETCH_INSTRUCTIONS_INFO;
  payload: InstructionsPayload;
}

export interface ShowProcedureGuideAction {
  type: typeof SHOW_PROCEDURE_GUIDE;
}

export interface HideProcedureGuideAction {
  type: typeof HIDE_PROCEDURE_GUIDE;
}

export type ChatFlowActionTypes = ChatInfoAction | SetChatMessageInfoAction | SetInstructionsAction;

export type AvailabilitySearchFilters = {
  locationIds: string[];
  practitionerIds: string[];
  duration: number;
  start: string;
  end: string;
  dateIds: string[];
  includedScheduleTags: string[];
};
export interface GetAvailableAppointmentsAction {
  type: typeof GET_AVAILABLE_APPOINTMENTS;
  status: ActionStatus;
  payload?: { availableAppointments?: AvailableAppointments; filters?: AvailabilitySearchFilters };
}

export interface ResetAvailableAppointmentsAction {
  type: typeof RESET_AVAILABLE_APPOINTMENTS;
  status: ActionStatus;
}
export interface AddAvailableSlotSelectionAction {
  type: typeof ADD_AVAILABLE_SLOT;
  status: ActionStatus;
  payload: { availableSlot: AvailableSlot };
}
export interface RemoveAvailableSlotSelectionAction {
  type: typeof REMOVE_AVAILABLE_SLOT;
  status: ActionStatus;
  payload: { availableSlot: AvailableSlot };
}
export interface SetSlotSelectionAction {
  type: typeof SET_SLOT_SELECTION;
  status: ActionStatus;
  payload: { availableSlots: AvailableSlot[] };
}
export interface AutoBookAppointmentAction {
  type: typeof AUTO_BOOK_APPOINTMENT;
  status: ActionStatus;
}

export type ProcedureGuideActionTypes = ShowProcedureGuideAction | HideProcedureGuideAction;

export type BookingActionTypes =
  | GetAvailableAppointmentsAction
  | ResetAvailableAppointmentsAction
  | AddAvailableSlotSelectionAction
  | RemoveAvailableSlotSelectionAction
  | SetSlotSelectionAction
  | AutoBookAppointmentAction;

export interface GetAppointmentRecordingsAction {
  type: typeof GET_APPOINTMENT_RECORDINGS;
  status: ActionStatus;
  payload?: AppointmentRecording[];
}

export interface DeleteAppointmentRecordingAction {
  type: typeof DELETE_APPOINTMENT_RECORDING;
  status: ActionStatus;
  payload?: { recordingFileId: number };
}

export type AppointmentRecordingsActions =
  | GetAppointmentRecordingsAction
  | DeleteAppointmentRecordingAction;

export interface ShowNotificationOverlayAction {
  type: typeof SHOW_NOTIFICATION_OVERLAY;
  payload: {
    type: NotificationType;
    meta?: NotificationMetaDictionary;
  };
}

export interface ClearNotificationOverlayAction {
  type: typeof CLEAR_NOTIFICATION_OVERLAY;
}

export type NotificationOverlayActions =
  | ShowNotificationOverlayAction
  | ClearNotificationOverlayAction;

export type AppActions =
  | ChatFlowActionTypes
  | ChatMessagesAction
  | ProcedureGuideActionTypes
  | BookingActionTypes
  | AppointmentRecordingsActions
  | NotificationOverlayActions;

export type Dispatch = (action: AppActions) => void;

export enum ActionStatus {
  loading = "loading",
  silentLoading = "silentLoading",
  success = "success",
  error = "error"
}
