import React from "react";
import { connect } from "react-redux";

import { Text } from "../../../components/ui";
import AppointmentSummaryCard from "./AppointmentSummaryCard";
import InfoTasksCard from "./InfoTasksCard";
import MoreDetailsCard from "./MoreDetailsCard";

import { Scroll } from "../../../components/layout";
import logos from "../../../assets/img";
import { motion } from "framer-motion";
import {
  updateAppointmentStatus as updateAppointmentStatusAction,
  UpdateAppointmentStatusActionData
} from "../../../actions/chat-actions";
import styles from "./index.module.scss";
import theme from "../../../theme/default";

interface ConversationHubProps {
  chatInfo: ChatInfo;
  token: string;
  updateAppointmentStatus: (token: string, data: UpdateAppointmentStatusActionData) => void;
}

const ConversationHub = ({ token, chatInfo, updateAppointmentStatus }: ConversationHubProps) => {
  const { organizationName, logo } = chatInfo;
  const multiLocations = chatInfo.locations.length > 1;

  const animation = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { type: "spring", stiffness: 200, damping: 25 }
  };

  const getLogo = (logoToCheck: string) => {
    return logos[logoToCheck]
      ? logos[logoToCheck]
      : `${process.env.REACT_APP_AWS_LOGO_BUCKET_URL}${logoToCheck}`;
  };

  return (
    <motion.div {...animation} className={styles.Motion}>
      <Scroll>
        <section className={styles.Section}>
          <header className={styles.Header}>
            {logo && (
              <img
                className={styles.Logo}
                src={getLogo(logo)}
                alt={`${organizationName} logo`}
                onError={(event) => {
                  // Hide image if fails to load
                  const newEvent = event;
                  newEvent.currentTarget.style.display = "none";
                }}
                onLoad={(event) => {
                  // Show image when loaded
                  const newEvent = event;
                  newEvent.currentTarget.style.display = "flex";
                }}
              />
            )}
          </header>
          <main id="conversationHubMain" className={styles.Main}>
            {/* Appointment Summary Card */}
            <AppointmentSummaryCard
              chatInfo={chatInfo}
              token={token}
              updateAppointmentStatus={updateAppointmentStatus}
            />
            {/* Chat Content Cards - Task and Card Variants */}
            {chatInfo?.conversations && chatInfo?.conversations.length > 0 && (
              <InfoTasksCard chatInfo={chatInfo} />
            )}
            {/* More Detail Card */}
            {!multiLocations && <MoreDetailsCard location={chatInfo.locations[0]} />}
          </main>
          <footer className={styles.Footer}>
            <Text variant="xsmall" textColor={theme.colors.grey[3]}>
              <a href="https://mikatahealth.com/" rel="noopener noreferrer" target="_blank">
                Powered by Mikata
              </a>
            </Text>
          </footer>
        </section>
      </Scroll>
    </motion.div>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    chatInfo: state.chatInfo
  };
}

export default connect(mapStateToProps, {
  updateAppointmentStatus: updateAppointmentStatusAction
})(ConversationHub);
