import {
  GET_AVAILABLE_APPOINTMENTS,
  RESET_AVAILABLE_APPOINTMENTS,
  ADD_AVAILABLE_SLOT,
  REMOVE_AVAILABLE_SLOT,
  SET_SLOT_SELECTION,
  AUTO_BOOK_APPOINTMENT
} from "../constants";
import {
  ActionStatus,
  AddAvailableSlotSelectionAction,
  RemoveAvailableSlotSelectionAction,
  BookingActionTypes,
  GetAvailableAppointmentsAction,
  SetSlotSelectionAction,
  AutoBookAppointmentAction
} from "../types/actions";

const initialState: BookingStore = {
  availableAppointments: {},
  loading: false,
  selectedAppointmentSlots: [],
  failedAttempts: 0,
  autoBookLoading: false
};

export const bookingReducer = (state = initialState, action: BookingActionTypes): BookingStore => {
  const { type } = action;
  switch (type) {
    case GET_AVAILABLE_APPOINTMENTS: {
      const { status, payload } = action as GetAvailableAppointmentsAction;

      const updatedAvailableAppointments =
        // eslint-disable-next-line no-nested-ternary
        status === ActionStatus.success
          ? (payload?.filters?.dateIds || []).reduce(
              (aa, dateId) => {
                aa[dateId] = payload?.availableAppointments?.[dateId]
                  ? payload.availableAppointments[dateId]
                  : [];
                return aa;
              },
              { ...state.availableAppointments }
            )
          : status === ActionStatus.loading
            ? (payload?.filters?.dateIds || []).reduce(
                (aa, dateId) => {
                  aa[dateId] = null;
                  return aa;
                },
                { ...state.availableAppointments }
              )
            : {};

      return {
        ...state,
        loading: status === ActionStatus.loading,
        availableAppointments: updatedAvailableAppointments
      };
    }
    case RESET_AVAILABLE_APPOINTMENTS: {
      return {
        ...state,
        availableAppointments: initialState.availableAppointments
      };
    }
    case ADD_AVAILABLE_SLOT: {
      const { payload } = action as AddAvailableSlotSelectionAction;
      return {
        ...state,
        selectedAppointmentSlots: [...state.selectedAppointmentSlots, payload.availableSlot]
      };
    }
    case REMOVE_AVAILABLE_SLOT: {
      const { payload } = action as RemoveAvailableSlotSelectionAction;

      const updatedSelectedSlots = state.selectedAppointmentSlots.filter(
        (slot: AvailableSlot) =>
          JSON.stringify(slot.slotIds) !== JSON.stringify(payload.availableSlot.slotIds)
      );
      return {
        ...state,
        selectedAppointmentSlots: updatedSelectedSlots
      };
    }
    case SET_SLOT_SELECTION: {
      const { payload } = action as SetSlotSelectionAction;

      return {
        ...state,
        selectedAppointmentSlots: payload.availableSlots
      };
    }
    case AUTO_BOOK_APPOINTMENT: {
      const { status } = action as AutoBookAppointmentAction;

      if (status === ActionStatus.success) {
        return {
          ...state,
          autoBookLoading: false
        };
      }

      if (status === ActionStatus.error) {
        return {
          ...state,
          failedAttempts: state.failedAttempts + 1,
          autoBookLoading: false
        };
      }

      return {
        ...state,
        autoBookLoading: status === ActionStatus.loading
      };
    }
    default:
      return state;
  }
};
