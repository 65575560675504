import axios from "axios";

import {
  ROOT_URL,
  GET_AVAILABLE_APPOINTMENTS,
  RESET_AVAILABLE_APPOINTMENTS,
  ADD_AVAILABLE_SLOT,
  REMOVE_AVAILABLE_SLOT,
  SET_SLOT_SELECTION,
  AUTO_BOOK_APPOINTMENT
} from "../constants";
import { ActionStatus, Dispatch, AvailabilitySearchFilters } from "../types/actions";

export type AvailabilitySearchResponse = {
  data: { availableAppointments: AvailableAppointments };
};

export const getAvailableAppointments =
  (token: string, filters: AvailabilitySearchFilters) => async (dispatch: Dispatch) => {
    // TODO:remove "token" function argument
    const sessionToken = sessionStorage.getItem("token");

    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" },
      params: {
        ...filters,
        locationIds: filters.locationIds.join(","),
        practitionerIds: filters.practitionerIds.join(","),
        includedScheduleTags: filters.includedScheduleTags.join(",")
      }
    };

    dispatch({
      type: GET_AVAILABLE_APPOINTMENTS,
      status: ActionStatus.loading,
      payload: { filters }
    });

    try {
      const response = await axios.get(`${ROOT_URL}/requests/appointments/slots`, config);
      return dispatch({
        type: GET_AVAILABLE_APPOINTMENTS,
        status: ActionStatus.success,
        payload: {
          availableAppointments: response.data,
          filters
        }
      });
    } catch (e: any) {
      return dispatch({
        type: GET_AVAILABLE_APPOINTMENTS,
        status: ActionStatus.error,
        payload: { filters }
      });
    }
  };

export const resetAvailableAppointments = () => {
  return {
    type: RESET_AVAILABLE_APPOINTMENTS,
    status: ActionStatus.success
  };
};

export const addAvailableSlotSelection = (availableSlot: AvailableSlot) => {
  return {
    type: ADD_AVAILABLE_SLOT,
    status: ActionStatus.success,
    payload: { availableSlot }
  };
};

export const removeAvailableSlotSelection = (availableSlot: AvailableSlot) => {
  return {
    type: REMOVE_AVAILABLE_SLOT,
    status: ActionStatus.success,
    payload: { availableSlot }
  };
};

export const setSlotSelection = (availableSlots: AvailableSlot[]) => {
  return {
    type: SET_SLOT_SELECTION,
    status: ActionStatus.success,
    payload: { availableSlots }
  };
};

export type AutoBookingData = {
  patient: { id: number };
  bookingData: {
    responseType: string;
    bookingReason: BookingReason;
    selectedAppointmentSlots: Array<AvailableSlot>;
  };
};

export type AutoBookingResponse = {
  status: number;
  data: { success?: boolean; emrAppointmentId: string };
};

export const autoBookAppointment =
  (token: string, autoBookingData: AutoBookingData, onSuccess?: () => void) =>
  async (dispatch: Dispatch) => {
    // TODO:remove "token" function argument
    const sessionToken = sessionStorage.getItem("token");

    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" }
    };

    dispatch({
      type: AUTO_BOOK_APPOINTMENT,
      status: ActionStatus.loading
    });

    try {
      const response: AutoBookingResponse = await axios.post(
        `${ROOT_URL}/requests/bookings/auto-book-appointment`,
        autoBookingData,
        config
      );

      if (!response?.data?.success) {
        return dispatch({
          type: AUTO_BOOK_APPOINTMENT,
          status: ActionStatus.error
        });
      }

      if (onSuccess) {
        onSuccess();
      }

      return dispatch({
        type: AUTO_BOOK_APPOINTMENT,
        status: ActionStatus.success
      });
    } catch (e: any) {
      return dispatch({
        type: AUTO_BOOK_APPOINTMENT,
        status: ActionStatus.error
      });
    }
  };
