import { ROOT_URL } from "../constants";
import axios from "axios";

const getSessionToken = () => {
  return sessionStorage.getItem("token") || "";
};

export const getSignedUrl = async (params: GetSignedUrlData) => {
  const {
    bucketName,
    fileExt = "",
    fileNamePrefix = "",
    downloadingFilename = "",
    token,
    conversationId
  } = params;
  // TODO:remove "token" function argument
  const sessionToken = getSessionToken();
  const resp = await fetch(
    `${ROOT_URL}/conversations/${conversationId}/get-signed-url/${bucketName}?fileExt=${fileExt}&fileNamePrefix=${fileNamePrefix}&downloadingFilename=${downloadingFilename}`,
    {
      method: "GET",
      headers: {
        Authorization: sessionToken,
        "Content-Type": "application/json"
      }
    }
  );
  const payload = await resp.json();
  return payload;
};

export const uploadFiles = async (url: string, data: string | ArrayBuffer | null) => {
  const resp = await axios.put(url, data);
  return resp;
};

export const createPatientUpload = async (fileName: string, conversationId: number) => {
  const data = { fileName };
  const sessionToken = getSessionToken();
  const config = {
    headers: { Authorization: sessionToken, "Content-Type": "application/json" }
  };
  const resp = await axios.post(
    `${ROOT_URL}/conversations/${conversationId}/secure-message-items`,
    data,
    config
  );
  return resp.data;
};

export const destroyPatientUpload = async (secureMessageId: number, conversationId: number) => {
  const sessionToken = getSessionToken();
  const config = {
    headers: { Authorization: sessionToken, "Content-Type": "application/json" }
  };
  const resp = await axios.delete(
    `${ROOT_URL}/conversations/${conversationId}/secure-message-items/${secureMessageId}`,
    config
  );
  return resp;
};
