import React from "react";

const PaperclipIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.416"
      height="16.425"
      viewBox="0 0 18.416 16.425"
    >
      <path
        id="noun-paper-clip-2288355"
        d="M131.022,60a4.937,4.937,0,0,0-6.975,0l-8.968,8.968a3.523,3.523,0,1,0,4.982,4.982l7.473-7.473a2.114,2.114,0,1,0-2.989-2.989l-4.982,4.982a.7.7,0,0,0,1,1l4.982-4.982a.7.7,0,1,1,1,1l-7.473,7.473a2.165,2.165,0,0,1-2.989,0,2.117,2.117,0,0,1,0-2.99L125.044,61a3.523,3.523,0,1,1,4.983,4.982l-5.979,6.975a.7.7,0,0,0,1,1l5.979-6.975a4.937,4.937,0,0,0,0-6.975Z"
        transform="translate(-114.049 -58.563)"
        fill="#c0c3ca"
      />
    </svg>
  );
};

export default PaperclipIcon;
