import React from "react";

import { Text } from "../../../ui";

import { DurationNode } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  node: DurationNode;
};

const Duration = ({ node }: PropsType) => {
  return (
    <div className={styles.Duration}>
      <div className={styles.Column}>
        <Text variant="meta">Est. duration</Text>
        <Text bold>{node.properties.duration}</Text>
      </div>
      <div className={styles.Column}>
        <Text variant="meta">Discomfort</Text>
        <Text bold>{node.properties.discomfort}</Text>
      </div>
    </div>
  );
};

export default Duration;
