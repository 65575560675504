import {
  FETCH_CHAT_INFO,
  UPDATE_APPT_INFO,
  AppointmentStates,
  INIT_CONVERSATION_HUB
} from "../constants";
import {
  ChatInfoAction,
  FetchChatInfoAction,
  InitConversationHubAction,
  UpdateChatInfoAction
} from "../types/actions";
import { ActionStatus } from "../types";

const initialState = {
  appointments: [],
  landingMessage: null,
  chatFlowTitle: "",
  chatFlowTags: [],
  isConversationComplete: false,
  organizationName: "",
  orgToken: "",
  locations: [],
  practitionerFullName: "",
  primaryColour: "",
  secondaryColour: "",
  logo: "",
  appearanceSettings: undefined,
  conversationId: 0,
  sessionId: 0,
  rescheduleLoading: false,
  confirmLoading: false,
  checkedInLoading: false,
  date: "",
  time: "",
  year: "",
  conversations: [],
  initialized: false,
  availableActions: []
};

export const chatInfoReducer = (state = initialState, action: ChatInfoAction): ChatInfo => {
  const { type } = action;
  switch (type) {
    case FETCH_CHAT_INFO: {
      const { payload } = action as FetchChatInfoAction;
      return payload && payload.data ? { ...state, ...payload.data } : state;
    }
    case UPDATE_APPT_INFO: {
      const { status, payload } = action as UpdateChatInfoAction;

      const newAppointments: Appointment[] = payload?.data?.appointmentUpdates?.length
        ? payload.data.appointmentUpdates.map((updatedAppt) => {
            const previousAppt = (state.appointments as Appointment[]).find(
              (previousApptItem) => previousApptItem.id === updatedAppt.id
            );
            return { ...previousAppt, ...updatedAppt } as Appointment;
          })
        : state.appointments;

      return {
        ...state,
        appointments: newAppointments,
        rescheduleLoading:
          payload.appointmentStatus === AppointmentStates.RESCHEDULE
            ? status === ActionStatus.loading
            : state.rescheduleLoading,
        confirmLoading:
          payload.appointmentStatus === AppointmentStates.CONFIRMED
            ? status === ActionStatus.loading
            : state.confirmLoading,
        checkedInLoading: payload.checkedIn
          ? status === ActionStatus.loading
          : state.checkedInLoading
      };
    }
    case INIT_CONVERSATION_HUB: {
      const { payload } = action as InitConversationHubAction;
      return payload && payload.data ? { ...state, ...payload.data } : state;
    }
    default:
      return state;
  }
};
