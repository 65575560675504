import React from "react";

type PropsType = {
  size?: number;
  strokeWidth?: number;
};

const Check = ({ size = 30, strokeWidth = 3 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size * (25 / 34)}px`}
      viewBox="0 0 34 25"
      preserveAspectRatio="xMidYMid"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        transform="translate(2 2)"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      >
        <path d="m9.808 20.192 2.243-2.243L13 17 29.192.808M.786 10.786 10 20" />
      </g>
    </svg>
  );
};

export default Check;
