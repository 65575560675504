/* Scroll
======================================= */

import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

interface Props {
  id?: string;
  customStyle?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const Scroll: React.FC<Props> = ({ className, customStyle, children, id }) => {
  return (
    <div className={cx(styles.Scrollbar, className)} id={id} style={customStyle}>
      {children}
    </div>
  );
};

Scroll.displayName = "Scroll";

export default Scroll;
