import React from "react";

type PropsType = {
  size?: number;
  strokeWidth?: number;
};

const Calendar = ({ size = 20, strokeWidth = 3 }: PropsType) => {
  return (
    <svg
      id="Icons_calendar"
      data-name="Icons/calendar"
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 16.364 18"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Combined_Shape"
            data-name="Combined Shape"
            d="M2.454,18A2.457,2.457,0,0,1,0,15.546V4.091A2.458,2.458,0,0,1,2.454,1.636H4.091V.818a.818.818,0,1,1,1.636,0v.818h4.909V.818a.818.818,0,0,1,1.637,0v.818h1.636a2.458,2.458,0,0,1,2.454,2.455V15.546A2.457,2.457,0,0,1,13.909,18Zm-.818-2.454a.819.819,0,0,0,.818.818H13.909a.819.819,0,0,0,.818-.818V8.182H1.636Zm13.091-9V4.091a.819.819,0,0,0-.818-.819H12.273v.819a.818.818,0,0,1-1.637,0V3.272H5.727v.819a.818.818,0,1,1-1.636,0V3.272H2.454a.819.819,0,0,0-.818.819V6.546Z"
            fill="#494b50"
          />
        </clipPath>
      </defs>
      <g id="Group_15" data-name="Group 15">
        <path
          id="Combined_Shape-2"
          data-name="Combined Shape"
          d="M2.454,18A2.457,2.457,0,0,1,0,15.546V4.091A2.458,2.458,0,0,1,2.454,1.636H4.091V.818a.818.818,0,1,1,1.636,0v.818h4.909V.818a.818.818,0,0,1,1.637,0v.818h1.636a2.458,2.458,0,0,1,2.454,2.455V15.546A2.457,2.457,0,0,1,13.909,18Zm-.818-2.454a.819.819,0,0,0,.818.818H13.909a.819.819,0,0,0,.818-.818V8.182H1.636Zm13.091-9V4.091a.819.819,0,0,0-.818-.819H12.273v.819a.818.818,0,0,1-1.637,0V3.272H5.727v.819a.818.818,0,1,1-1.636,0V3.272H2.454a.819.819,0,0,0-.818.819V6.546Z"
          fill="#494b50"
        />
      </g>
    </svg>
  );
};

export default Calendar;
