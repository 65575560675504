import { ChatFlowTags, ChatCardTypes } from "../../../constants";

interface StartMessage {
  payloadContent: string;
  cardType: string;
  sender: string;
  chatFlowMessageId: number;
}

export const getDefaultStartMessage = (
  organizationName: string,
  chatType: string
): StartMessage => {
  const defaultApptRequestStart = {
    payloadContent: JSON.stringify({
      header: "Request your next appointment",
      paragraphs: [
        `Book an appointment quickly and easily by chatting with Mika, your automated care assistant at ${organizationName}.`
      ]
    }),
    cardType: ChatCardTypes.LANDING,
    sender: "mikata",
    chatFlowMessageId: 1
  };

  const defaultAssessmentStart = {
    payloadContent: JSON.stringify({
      header: "Complete your assessment",
      paragraphs: [
        `Complete your assessment quickly and easily by chatting with Mika, your automated care assistant at ${organizationName}.`
      ]
    }),
    cardType: ChatCardTypes.LANDING,
    sender: "mikata",
    chatFlowMessageId: 1
  };

  const defaultSecureMessageStart = {
    payloadContent: JSON.stringify({
      header: "View secure message",
      paragraphs: [
        'The information sent from your clinic is secured, private, and should only be viewed by you as the patient. If you have any concerns over the privacy of this information, please view our complete privacy policy <a href="https://mikatahealth.com/privacy_policy" target="_blank">here</a>',
        "If you have any questions, please contact your clinic. ",
        "This link will expire 24 hours after your appt start time."
      ]
    }),
    cardType: ChatCardTypes.LANDING,
    sender: "mikata",
    chatFlowMessageId: 1
  };

  const defaultPostVisitStart = {
    payloadContent: JSON.stringify({
      header: "Appointment Survey",
      paragraphs: [
        `You are invited to take part in this survey because you have recently had a visit at ${organizationName}.`,
        `Your responses to the questions on this survey will help us improve the care we provide.`,
        `It will take approximately 5 minutes to complete and participation in the survey is completely voluntary. All your responses to the survey questions will be kept confidential.`
      ]
    }),
    cardType: ChatCardTypes.LANDING,
    sender: "mikata",
    chatFlowMessageId: 1
  };

  const defaultStartMessage: { [key: string]: StartMessage } = {
    [ChatFlowTags.APPOINTMENT_REQUEST]: defaultApptRequestStart,
    [ChatFlowTags.ASSESSMENT]: defaultAssessmentStart,
    [ChatFlowTags.SECURE_DIRECT_MESSAGE]: defaultSecureMessageStart,
    [ChatFlowTags.POSTVISIT]: defaultPostVisitStart
  };

  return defaultStartMessage[chatType];
};
