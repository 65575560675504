import React from "react";

import WebSocketProvider from "../../../providers/WebSocketProvider";

import ScribeVisitManager from "./ScribeVisitManager";

interface ScribeVisitCardProps {
  msg: Message;
  isCurrentMessage: boolean;
}

const ScribeVisitCard = ({ msg, isCurrentMessage }: ScribeVisitCardProps) => {
  return (
    <WebSocketProvider>
      <ScribeVisitManager msg={msg} isCurrentMessage={isCurrentMessage} />
    </WebSocketProvider>
  );
};

export default ScribeVisitCard;
