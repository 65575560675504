import React, { useRef, useEffect, useCallback } from "react";

type PropsType = {
  onTimeout: () => void;
  timeoutMilliseconds: number;
};

const useCountdown = ({ onTimeout, timeoutMilliseconds }: PropsType) => {
  const timerRef = useRef<any>();

  const resetCountdown = useCallback(() => {
    // reset timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      onTimeout();
    }, timeoutMilliseconds);
  }, [onTimeout, timeoutMilliseconds]);

  useEffect(() => {
    // init timer
    timerRef.current = setTimeout(() => {
      onTimeout();
    }, timeoutMilliseconds);

    return () => {
      // clear timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { resetCountdown };
};

export default useCountdown;
