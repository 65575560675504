import { ChatFlowTags } from "../constants";

const getChatType = (chatArr: string[]) => {
  const chatTypes: string[] = [
    ChatFlowTags.APPOINTMENT_REQUEST,
    ChatFlowTags.ASSESSMENT,
    ChatFlowTags.PREVISIT,
    ChatFlowTags.POSTVISIT,
    ChatFlowTags.SECURE_DIRECT_MESSAGE
  ];

  const chatType = chatArr.find((chatArrItem) => chatTypes.includes(chatArrItem));

  return chatType;
};

export default getChatType;
