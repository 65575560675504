import React from "react";

type PropsType = {
  size?: number;
  strokeWidth?: number;
};

const MapPin = ({ size = 20, strokeWidth = 3 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill="#494B50"
        fillRule="evenodd"
        d="M11.691 18.946a25.96 25.96 0 0 0 2.664-2.435c2.476-2.614 3.978-5.368 3.978-8.178a8.333 8.333 0 1 0-16.666 0c0 2.81 1.502 5.564 3.978 8.178a25.945 25.945 0 0 0 3.545 3.105c.162.117.28.2.348.244.28.187.644.187.924 0 .067-.045.186-.127.348-.244.267-.193.563-.416.881-.67Zm1.454-3.582A24.303 24.303 0 0 1 10 18.144a24.299 24.299 0 0 1-3.145-2.78c-2.211-2.334-3.522-4.736-3.522-7.03a6.667 6.667 0 0 1 13.334 0c0 2.294-1.31 4.696-3.522 7.03ZM10 11.667A3.333 3.333 0 1 1 10 5a3.333 3.333 0 0 1 0 6.667Zm1.667-3.334a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#494B50"
        fillRule="evenodd"
        d="M11.691 18.946a25.96 25.96 0 0 0 2.664-2.435c2.476-2.614 3.978-5.368 3.978-8.178a8.333 8.333 0 1 0-16.666 0c0 2.81 1.502 5.564 3.978 8.178a25.945 25.945 0 0 0 3.545 3.105c.162.117.28.2.348.244.28.187.644.187.924 0 .067-.045.186-.127.348-.244.267-.193.563-.416.881-.67Zm1.454-3.582A24.303 24.303 0 0 1 10 18.144a24.299 24.299 0 0 1-3.145-2.78c-2.211-2.334-3.522-4.736-3.522-7.03a6.667 6.667 0 0 1 13.334 0c0 2.294-1.31 4.696-3.522 7.03ZM10 11.667A3.333 3.333 0 1 1 10 5a3.333 3.333 0 0 1 0 6.667Zm1.667-3.334a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default MapPin;
