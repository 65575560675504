/* Theme > typeography
========================================= */

const typeography = {
  fonts: {
    heading: `hk_groteskregular, Helvetica, Arial, sans-serif`,
    body: `hk_groteskregular, Helvetica, Arial, sans-serif`,
    bold: `hk_groteskbold, Helvetica, Arial, sans-serif`
  },

  fontSizes: [
    "12px", // 0 smallest
    "14px", // 1
    "15px", // 2
    "16px", // 3 normal
    "18px", // 4
    "24px" // 5 largest
  ],

  fontWeights: {
    normal: 400,
    bold: 600,
    bolder: 700
  }
};

export default typeography;
