import React, { useEffect } from "react";
import { connect } from "react-redux";

import Loader from "../../../Loader";

import useWebSocketConnection from "../useWebSocketConnection";
import InfoTermsConsent from "../InfoTermsConsent";
import StreamingStatus from "../StreamingStatus";

import {
  fetchNextMessage as fetchNextMessageAction,
  undoUserResponse as undoUserResponseAction,
  UndoUserResponseData
} from "../../../../../actions";

import { ChatCardTypes } from "../../../../../constants";

import { PatientConsentStatus, ChatFlowControlFlags } from "../../../../../types";

type ScribeVisitManagerProps = {
  msg: Message;
  isCurrentMessage: boolean;

  chatMessageLoading: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  undoUserResponse: (data: UndoUserResponseData) => void;
};

const ScribeVisitManager = ({
  msg,
  isCurrentMessage,
  chatMessageLoading,
  fetchNextMessage,
  undoUserResponse
}: ScribeVisitManagerProps) => {
  const {
    recording,
    connected = false,
    patientConsentLoading,
    endScribeSession
  } = useWebSocketConnection();
  const initializing = !recording;

  // Get next message when patient consent denied
  useEffect(() => {
    if (isCurrentMessage && !chatMessageLoading && !patientConsentLoading) {
      if (recording && recording.patientConsentStatus === PatientConsentStatus.DENIED) {
        fetchNextMessage({
          lastReceivedMessageId: null,
          userResponse: ChatFlowControlFlags.SCRIBE_VISIT_CONSENT_DENIED,
          userResponseType: ChatCardTypes.SCRIBE_VISIT,
          chatFlowId: null,
          showUserResponse: false
        });
      }
    }
  }, [
    recording?.patientConsentStatus,
    recording?.sessionCompleted,
    isCurrentMessage,
    patientConsentLoading,
    chatMessageLoading
  ]);

  // Get next message when patient consent accepted
  useEffect(() => {
    if (
      isCurrentMessage &&
      !chatMessageLoading &&
      !patientConsentLoading &&
      recording &&
      recording.patientConsentStatus === PatientConsentStatus.ACCEPTED &&
      recording.sessionCompleted
    ) {
      fetchNextMessage({
        lastReceivedMessageId: null,
        userResponse: ChatFlowControlFlags.SCRIBE_VISIT_COMPLETED,
        userResponseType: ChatCardTypes.SCRIBE_VISIT,
        chatFlowId: null,
        showUserResponse: false
      });
    }
  }, [
    isCurrentMessage,
    patientConsentLoading,
    chatMessageLoading,
    recording?.patientConsentStatus,
    recording?.sessionCompleted
  ]);

  return (
    <div>
      {initializing ? (
        <Loader size={32} />
      ) : (
        <>
          <InfoTermsConsent
            msg={msg}
            isCurrentMessage={isCurrentMessage}
            undoUserResponse={() => {
              if (msg?.id) undoUserResponse({ messageTemplateId: msg.id });
            }}
          />
          <StreamingStatus
            connected={connected}
            recording={recording}
            endScribeSession={endScribeSession}
          />
        </>
      )}
    </div>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    chatMessageLoading: state.chatMessages.loading
  };
}

export default connect(mapStateToProps, {
  fetchNextMessage: fetchNextMessageAction,
  undoUserResponse: undoUserResponseAction
})(ScribeVisitManager);
