import { combineReducers } from "redux";

import { checklistInfoReducer } from "./checklistInfoReducer";
import { chatMessageInfoReducer } from "./chatMessageInfoReducer";
import { chatMessagesReducer } from "./chatMessagesReducer";
import { chatInfoReducer } from "./chatInfoReducer";
import { bookingReducer } from "./bookingReducer";
import { appointmentRecordingsReducer } from "./appointmentRecordingsReducer";
import { messagesReducer } from "./messagesReducer";
import { notificationOverlayReducer } from "./notificationOverlay";

export default combineReducers({
  appointmentRecordings: appointmentRecordingsReducer,
  booking: bookingReducer,
  checklistInfo: checklistInfoReducer,
  chatMessageInfo: chatMessageInfoReducer,
  chatMessages: chatMessagesReducer,
  chatInfo: chatInfoReducer,
  messages: messagesReducer,
  notificationOverlay: notificationOverlayReducer
});
