/* Loader
======================================= */
import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

interface Props {
  size?: number;
  center?: boolean;
}

const Loader = ({ size = 50, center }: Props) => {
  return (
    <div
      className={cx(styles.Wrapper, {
        [styles.WrapperCenter]: center
      })}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <svg className={styles.Loader} viewBox="25 25 50 50">
        <circle className={styles.Background} cx="50" cy="50" r="20" fill="none" strokeWidth="5" />
        <circle className={styles.Path} cx="50" cy="50" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

export default Loader;
