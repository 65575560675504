/* Icons
Important: make sure these are valid JSX tags
========================================= */

import React from "react";

const icons = {
  logo: (
    <svg
      width={`${100}px`}
      height={`${100 * (347 / 461)}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 461 347"
      fill="reset"
    >
      <path
        d="M269.43 58.5A108.129 108.129 0 0 0 196 87.22a9.19 9.19 0 0 1-12.5 0 108.129 108.129 0 0 0-73.43-28.72C49.69 58.5.75 108.05.75 169.17v146.61c0 16.27 12.49 30.12 28.55 30.7a29.803 29.803 0 0 0 21.738-8.469 29.805 29.805 0 0 0 8.982-21.531v-146c0-28 22.41-50.67 50-50.67 27.64 0 50.05 22.69 50.05 50.67v145.3c0 16.27 12.49 30.12 28.55 30.7a29.805 29.805 0 0 0 28.417-18.32 29.797 29.797 0 0 0 2.293-11.68v-146c0-28 22.41-50.67 50.05-50.67 27.64 0 50.05 22.69 50.05 50.67v145.3c0 16.27 12.5 30.12 28.56 30.7a29.805 29.805 0 0 0 28.417-18.32 29.797 29.797 0 0 0 2.293-11.68V169.17c.05-61.12-48.89-110.67-109.27-110.67Z"
        fill="#003125"
      />
      <path
        d="M30.75 59c16.292 0 29.5-13.208 29.5-29.5S47.042 0 30.75 0 1.25 13.208 1.25 29.5 14.458 59 30.75 59Z"
        fill="#00D598"
      />
      <path
        d="M430.75 347c16.292 0 29.5-13.208 29.5-29.5s-13.208-29.5-29.5-29.5-29.5 13.208-29.5 29.5 13.208 29.5 29.5 29.5Z"
        fill="#FF878F"
      />
    </svg>
  ),
  headerIcon: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2V1a1 1 0 112 0v1h2a3 3 0 013 3v14a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2V1a1 1 0 012 0v1h6zM4 10v9a1 1 0 001 1h14a1 1 0 001-1v-9H4zm16-2H4V5a1 1 0 011-1h2v1a1 1 0 002 0V4h6v1a1 1 0 102 0V4h2a1 1 0 011 1v3z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2V1a1 1 0 112 0v1h2a3 3 0 013 3v14a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2V1a1 1 0 012 0v1h6zM4 10v9a1 1 0 001 1h14a1 1 0 001-1v-9H4zm16-2H4V5a1 1 0 011-1h2v1a1 1 0 002 0V4h6v1a1 1 0 102 0V4h2a1 1 0 011 1v3z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  settings: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.299 8.715A.647.647 0 0020.83 9H21a3 3 0 110 6h-.086a.65.65 0 00-.595.394.65.65 0 00.118.719l.06.06a3 3 0 11-4.244 4.244l-.052-.052a.654.654 0 00-.727-.126.649.649 0 00-.394.591V21a3 3 0 11-6 0 .698.698 0 00-.484-.685.647.647 0 00-.709.122l-.06.06a3 3 0 11-4.244-4.244l.052-.052a.654.654 0 00.126-.727.649.649 0 00-.591-.394H3a3 3 0 110-6 .698.698 0 00.685-.484.647.647 0 00-.122-.709l-.06-.06a3 3 0 114.244-4.244l.052.052a.65.65 0 00.717.13 1 1 0 01.2-.064A.647.647 0 009 3.17V3a3 3 0 116 0v.086c.001.26.156.493.404.6a.647.647 0 00.709-.123l.06-.06a3 3 0 114.244 4.244l-.052.052a.65.65 0 00-.13.717c.028.064.05.131.064.2zM9.129 5.672a2.655 2.655 0 01-2.736-.615l-.06-.06a1 1 0 10-1.416 1.416l.068.068c.757.774.967 1.932.554 2.864A2.657 2.657 0 013.09 11.08H3a1 1 0 100 2h.174a2.646 2.646 0 012.42 1.596 2.654 2.654 0 01-.537 2.931l-.06.06a1 1 0 101.416 1.416l.068-.068c.774-.757 1.932-.967 2.864-.553a2.657 2.657 0 011.735 2.448V21a1 1 0 102 0v-.174a2.646 2.646 0 011.596-2.42 2.654 2.654 0 012.931.537l.06.06a1 1 0 101.416-1.416l-.068-.068a2.65 2.65 0 01-.53-2.923A2.648 2.648 0 0120.91 13H21a1 1 0 100-2h-.174a2.65 2.65 0 01-2.425-1.606.994.994 0 01-.073-.264 2.655 2.655 0 01.615-2.737l.06-.06a1 1 0 10-1.416-1.416l-.068.068a2.646 2.646 0 01-2.913.534A2.651 2.651 0 0113 3.09V3a1 1 0 10-2 0v.174a2.65 2.65 0 01-1.606 2.425 1 1 0 01-.264.073zM8 12a4 4 0 108 0 4 4 0 00-8 0zm4 2a2 2 0 100-4 2 2 0 000 4z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.299 8.715A.647.647 0 0020.83 9H21a3 3 0 110 6h-.086a.65.65 0 00-.595.394.65.65 0 00.118.719l.06.06a3 3 0 11-4.244 4.244l-.052-.052a.654.654 0 00-.727-.126.649.649 0 00-.394.591V21a3 3 0 11-6 0 .698.698 0 00-.484-.685.647.647 0 00-.709.122l-.06.06a3 3 0 11-4.244-4.244l.052-.052a.654.654 0 00.126-.727.649.649 0 00-.591-.394H3a3 3 0 110-6 .698.698 0 00.685-.484.647.647 0 00-.122-.709l-.06-.06a3 3 0 114.244-4.244l.052.052a.65.65 0 00.717.13 1 1 0 01.2-.064A.647.647 0 009 3.17V3a3 3 0 116 0v.086c.001.26.156.493.404.6a.647.647 0 00.709-.123l.06-.06a3 3 0 114.244 4.244l-.052.052a.65.65 0 00-.13.717c.028.064.05.131.064.2zM9.129 5.672a2.655 2.655 0 01-2.736-.615l-.06-.06a1 1 0 10-1.416 1.416l.068.068c.757.774.967 1.932.554 2.864A2.657 2.657 0 013.09 11.08H3a1 1 0 100 2h.174a2.646 2.646 0 012.42 1.596 2.654 2.654 0 01-.537 2.931l-.06.06a1 1 0 101.416 1.416l.068-.068c.774-.757 1.932-.967 2.864-.553a2.657 2.657 0 011.735 2.448V21a1 1 0 102 0v-.174a2.646 2.646 0 011.596-2.42 2.654 2.654 0 012.931.537l.06.06a1 1 0 101.416-1.416l-.068-.068a2.65 2.65 0 01-.53-2.923A2.648 2.648 0 0120.91 13H21a1 1 0 100-2h-.174a2.65 2.65 0 01-2.425-1.606.994.994 0 01-.073-.264 2.655 2.655 0 01.615-2.737l.06-.06a1 1 0 10-1.416-1.416l-.068.068a2.646 2.646 0 01-2.913.534A2.651 2.651 0 0113 3.09V3a1 1 0 10-2 0v.174a2.65 2.65 0 01-1.606 2.425 1 1 0 01-.264.073zM8 12a4 4 0 108 0 4 4 0 00-8 0zm4 2a2 2 0 100-4 2 2 0 000 4z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  edit: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.707 1.293l4 4a1 1 0 010 1.414l-10 10A1 1 0 0112 17H8a1 1 0 01-1-1v-4a1 1 0 01.293-.707l10-10a1 1 0 011.414 0zM19 14.66V20a1 1 0 01-1 1H4a1 1 0 01-1-1V6a1 1 0 011-1h5.34a1 1 0 100-2H4a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-5.34a1 1 0 10-2 0zM9 15v-2.586l9-9L20.586 6l-9 9H9z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.707 1.293l4 4a1 1 0 010 1.414l-10 10A1 1 0 0112 17H8a1 1 0 01-1-1v-4a1 1 0 01.293-.707l10-10a1 1 0 011.414 0zM19 14.66V20a1 1 0 01-1 1H4a1 1 0 01-1-1V6a1 1 0 011-1h5.34a1 1 0 100-2H4a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-5.34a1 1 0 10-2 0zM9 15v-2.586l9-9L20.586 6l-9 9H9z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  help: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a8.962 8.962 0 005.618-1.968l-2.86-2.86A4.977 4.977 0 0112 17a4.977 4.977 0 01-2.757-.828l-2.86 2.86A8.962 8.962 0 0012 21zm-7.032-3.382A8.962 8.962 0 013 12c0-2.125.736-4.078 1.968-5.618l2.86 2.86A4.977 4.977 0 007 12c0 1.02.305 1.967.828 2.757l-2.86 2.86zM21 12a8.962 8.962 0 01-1.968 5.618l-2.86-2.86c.523-.791.828-1.739.828-2.758 0-1.02-.305-1.967-.828-2.757l2.86-2.86A8.962 8.962 0 0121 12zm-3.382-7.032l-2.86 2.86A4.977 4.977 0 0012 7c-1.02 0-1.967.305-2.757.828l-2.86-2.86A8.962 8.962 0 0112 3c2.125 0 4.078.736 5.618 1.968zM12 15a3 3 0 100-6 3 3 0 000 6z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a8.962 8.962 0 005.618-1.968l-2.86-2.86A4.977 4.977 0 0112 17a4.977 4.977 0 01-2.757-.828l-2.86 2.86A8.962 8.962 0 0012 21zm-7.032-3.382A8.962 8.962 0 013 12c0-2.125.736-4.078 1.968-5.618l2.86 2.86A4.977 4.977 0 007 12c0 1.02.305 1.967.828 2.757l-2.86 2.86zM21 12a8.962 8.962 0 01-1.968 5.618l-2.86-2.86c.523-.791.828-1.739.828-2.758 0-1.02-.305-1.967-.828-2.757l2.86-2.86A8.962 8.962 0 0121 12zm-3.382-7.032l-2.86 2.86A4.977 4.977 0 0012 7c-1.02 0-1.967.305-2.757.828l-2.86-2.86A8.962 8.962 0 0112 3c2.125 0 4.078.736 5.618 1.968zM12 15a3 3 0 100-6 3 3 0 000 6z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  chat: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.583 19.419l3.803-1.268a1 1 0 01.767.056A7.381 7.381 0 0012.5 19a7.501 7.501 0 006.708-4.15 7.379 7.379 0 00.793-3.347l.001-.448c-.21-3.807-3.25-6.846-7.001-7.055h-.503a7.385 7.385 0 00-3.35.795 7.5 7.5 0 00-4.147 6.708 7.38 7.38 0 00.792 3.346 1 1 0 01.056.767L4.583 19.42zm17.419-7.92a9.374 9.374 0 01-1.006 4.248A9.499 9.499 0 0112.504 21a9.379 9.379 0 01-3.868-.824L3.318 21.95a1 1 0 01-1.265-1.265l1.773-5.318A9.498 9.498 0 018.25 3.007 9.377 9.377 0 0112.5 2l.556.002c4.825.266 8.677 4.118 8.945 8.998v.499z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="2" y="2" width="21" height="21">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.583 19.419l3.803-1.268a1 1 0 01.767.056A7.381 7.381 0 0012.5 19a7.501 7.501 0 006.708-4.15 7.379 7.379 0 00.793-3.347l.001-.448c-.21-3.807-3.25-6.846-7.001-7.055h-.503a7.385 7.385 0 00-3.35.795 7.5 7.5 0 00-4.147 6.708 7.38 7.38 0 00.792 3.346 1 1 0 01.056.767L4.583 19.42zm17.419-7.92a9.374 9.374 0 01-1.006 4.248A9.499 9.499 0 0112.504 21a9.379 9.379 0 01-3.868-.824L3.318 21.95a1 1 0 01-1.265-1.265l1.773-5.318A9.498 9.498 0 018.25 3.007 9.377 9.377 0 0112.5 2l.556.002c4.825.266 8.677 4.118 8.945 8.998v.499z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  upload: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.021 2.058A9 9 0 002.256 16.96a1 1 0 001.499-1.324 7 7 0 1112.022-6.388 1 1 0 00.969.75h1.26a4 4 0 011.91 7.512 1 1 0 10.958 1.756 6 6 0 00-2.868-11.268h-.521a9 9 0 00-7.464-5.94zm6.692 14.647a1 1 0 01-1.415 0l-2.293-2.293v6.586a1 1 0 01-2 0v-6.586l-2.292 2.293a1 1 0 01-1.415-1.414l4-4 .006-.006m1.409.006l-.007-.007.007.007z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="2" width="25" height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.021 2.058A9 9 0 002.256 16.96a1 1 0 001.499-1.324 7 7 0 1112.022-6.388 1 1 0 00.969.75h1.26a4 4 0 011.91 7.512 1 1 0 10.958 1.756 6 6 0 00-2.868-11.268h-.521a9 9 0 00-7.464-5.94zm6.692 14.647a1 1 0 01-1.415 0l-2.293-2.293v6.586a1 1 0 01-2 0v-6.586l-2.292 2.293a1 1 0 01-1.415-1.414l4-4 .006-.006m1.409.006l-.007-.007.007.007z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  close: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.586l5.293-5.293a1 1 0 111.414 1.414L13.414 12l5.293 5.293a1 1 0 01-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 01-1.414-1.414L10.586 12 5.293 6.707a1 1 0 011.414-1.414L12 10.586z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 10.586l5.293-5.293a1 1 0 111.414 1.414L13.414 12l5.293 5.293a1 1 0 01-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 01-1.414-1.414L10.586 12 5.293 6.707a1 1 0 011.414-1.414L12 10.586z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  email: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3h16a3.006 3.006 0 013 3.018V18c0 1.652-1.348 3-3 3H4c-1.652 0-3-1.348-3-3V6c0-1.652 1.348-3 3-3zm16.893 2.554A1.006 1.006 0 0020 5H4c-.388 0-.728.227-.893.554L12 11.779l8.893-6.225zM3 7.921l8.427 5.898a1 1 0 001.146 0L21 7.921V18c0 .548-.452 1-1 1H4c-.548 0-1-.452-1-1V7.92z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="3" width="23" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3h16a3.006 3.006 0 013 3.018V18c0 1.652-1.348 3-3 3H4c-1.652 0-3-1.348-3-3V6c0-1.652 1.348-3 3-3zm16.893 2.554A1.006 1.006 0 0020 5H4c-.388 0-.728.227-.893.554L12 11.779l8.893-6.225zM3 7.921l8.427 5.898a1 1 0 001.146 0L21 7.921V18c0 .548-.452 1-1 1H4c-.548 0-1-.452-1-1V7.92z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  user: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12a5 5 0 110-10 5 5 0 010 10zm9 9v-2a5 5 0 00-5-5H8a5 5 0 00-5 5v2a1 1 0 102 0v-2a3 3 0 013-3h8a3 3 0 013 3v2a1 1 0 102 0zM15 7a3 3 0 11-6 0 3 3 0 016 0z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="3" y="2" width="18" height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12a5 5 0 110-10 5 5 0 010 10zm9 9v-2a5 5 0 00-5-5H8a5 5 0 00-5 5v2a1 1 0 102 0v-2a3 3 0 013-3h8a3 3 0 013 3v2a1 1 0 102 0zM15 7a3 3 0 11-6 0 3 3 0 016 0z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  users: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 12A5 5 0 119 2a5 5 0 010 10zm9 9v-2a5 5 0 00-5-5H5a5 5 0 00-5 5v2a1 1 0 102 0v-2a3 3 0 013-3h8a3 3 0 013 3v2a1 1 0 102 0zm6-2v2a1 1 0 11-2 0v-2a3 3 0 00-2.25-2.902 1 1 0 11.5-1.936A5 5 0 0124 19zM15.752 4.099a3 3 0 010 5.812 1 1 0 10.496 1.938 5 5 0 000-9.688 1 1 0 10-.496 1.938zM12 7a3 3 0 11-6 0 3 3 0 016 0z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="2" width="24" height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 12A5 5 0 119 2a5 5 0 010 10zm9 9v-2a5 5 0 00-5-5H5a5 5 0 00-5 5v2a1 1 0 102 0v-2a3 3 0 013-3h8a3 3 0 013 3v2a1 1 0 102 0zm6-2v2a1 1 0 11-2 0v-2a3 3 0 00-2.25-2.902 1 1 0 11.5-1.936A5 5 0 0124 19zM15.752 4.099a3 3 0 010 5.812 1 1 0 10.496 1.938 5 5 0 000-9.688 1 1 0 10-.496 1.938zM12 7a3 3 0 11-6 0 3 3 0 016 0z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  arrowDown: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17.586V4a1 1 0 112 0v13.586l4.293-4.293a1 1 0 011.414 1.414l-6 6a.997.997 0 01-.698.293M11 17.586l-4.293-4.293a1 1 0 00-1.414 1.414l6 6c.193.193.445.29.698.293"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="3" width="14" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 17.586V4a1 1 0 112 0v13.586l4.293-4.293a1 1 0 011.414 1.414l-6 6a.997.997 0 01-.698.293M11 17.586l-4.293-4.293a1 1 0 00-1.414 1.414l6 6c.193.193.445.29.698.293"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  arrowLeft: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.707 5.293a1 1 0 010 1.414L6.414 11H20a1 1 0 110 2H6.414l4.293 4.293a1 1 0 01-1.414 1.414l-6-6m0-1.415l6-6a1 1 0 011.414 0m-7.414 6l-.002.003a.997.997 0 000 1.41"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="3" y="5" width="18" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.707 5.293a1 1 0 010 1.414L6.414 11H20a1 1 0 110 2H6.414l4.293 4.293a1 1 0 01-1.414 1.414l-6-6m0-1.415l6-6a1 1 0 011.414 0m-7.414 6l-.002.003a.997.997 0 000 1.41"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  arrowRight: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.293 18.707a1 1 0 010-1.414L17.586 13H4a1 1 0 110-2h13.586l-4.293-4.293a1 1 0 011.414-1.414l6 6A.997.997 0 0121 12m-.076.383a.997.997 0 01-.217.324l-6 6a1 1 0 01-1.414 0m7.631-6.324a.996.996 0 00.076-.374l-.076.374z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="3" y="5" width="18" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.293 18.707a1 1 0 010-1.414L17.586 13H4a1 1 0 110-2h13.586l-4.293-4.293a1 1 0 011.414-1.414l6 6A.997.997 0 0121 12m-.076.383a.997.997 0 01-.217.324l-6 6a1 1 0 01-1.414 0m7.631-6.324a.996.996 0 00.076-.374l-.076.374z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  arrowUL: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.414 7l10.293 10.293a1 1 0 01-1.414 1.414L7 8.414V15a1 1 0 11-2 0V6a.997.997 0 011-1h9a1 1 0 110 2H8.414z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.414 7l10.293 10.293a1 1 0 01-1.414 1.414L7 8.414V15a1 1 0 11-2 0V6a.997.997 0 011-1h9a1 1 0 110 2H8.414z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  arrowUR: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6v9a1 1 0 11-2 0V8.414L6.707 18.707a1 1 0 01-1.414-1.414L15.586 7H9a1 1 0 010-2h9c.275 0 .524.111.705.29l.006.007A.998.998 0 0119 6z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 6v9a1 1 0 11-2 0V8.414L6.707 18.707a1 1 0 01-1.414-1.414L15.586 7H9a1 1 0 010-2h9c.275 0 .524.111.705.29l.006.007A.998.998 0 0119 6z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  arrowDL: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 15.586L17.293 5.293a1 1 0 111.414 1.414L8.414 17H15a1 1 0 110 2H6a.997.997 0 01-.703-.289l-.006-.006A.997.997 0 015 18V9a1 1 0 012 0v6.586z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 15.586L17.293 5.293a1 1 0 111.414 1.414L8.414 17H15a1 1 0 110 2H6a.997.997 0 01-.703-.289l-.006-.006A.997.997 0 015 18V9a1 1 0 012 0v6.586z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  arrowDR: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.586 17L5.293 6.707a1 1 0 011.414-1.414L17 15.586V9a1 1 0 112 0v9a.997.997 0 01-1 1H9a1 1 0 110-2h6.586z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.586 17L5.293 6.707a1 1 0 011.414-1.414L17 15.586V9a1 1 0 112 0v9a.997.997 0 01-1 1H9a1 1 0 110-2h6.586z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  chevronUp: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.414l5.293 5.293a1 1 0 001.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414L12 10.414z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="8" width="14" height="8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 10.414l5.293 5.293a1 1 0 001.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414L12 10.414z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  chevronDown: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.086L6.707 8.793a1 1 0 00-1.414 1.414l6 6a1 1 0 001.414 0l6-6a1 1 0 00-1.414-1.414L12 14.086z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="8" width="14" height="9">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14.086L6.707 8.793a1 1 0 00-1.414 1.414l6 6a1 1 0 001.414 0l6-6a1 1 0 00-1.414-1.414L12 14.086z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  chevronLeft: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.414 12l5.293-5.293a1 1 0 00-1.414-1.414l-6 6a1 1 0 000 1.414l6 6a1 1 0 001.414-1.414L10.414 12z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="8" y="5" width="8" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.414 12l5.293-5.293a1 1 0 00-1.414-1.414l-6 6a1 1 0 000 1.414l6 6a1 1 0 001.414-1.414L10.414 12z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  chevronRight: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.586 12l-5.293 5.293a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414l-6-6a1 1 0 00-1.414 1.414L13.586 12z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="8" y="5" width="8" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.586 12l-5.293 5.293a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414l-6-6a1 1 0 00-1.414 1.414L13.586 12z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  clock: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm0-16a1 1 0 011 1v5.586l2.707 2.707a1 1 0 01-1.414 1.414l-3-3A1 1 0 0111 12V6a1 1 0 011-1z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm0-16a1 1 0 011 1v5.586l2.707 2.707a1 1 0 01-1.414 1.414l-3-3A1 1 0 0111 12V6a1 1 0 011-1z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  bell: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 14V9A6 6 0 006 9v5c0 .729-.195 1.412-.535 2h13.07A3.982 3.982 0 0118 14zm4 4H2c-1.333 0-1.333-2 0-2a2 2 0 002-2V9a8 8 0 1116 0v5a2 2 0 002 2c1.333 0 1.333 2 0 2zm-7.405 3.502a3 3 0 01-5.19 0A1 1 0 0110.27 20h3.46a1 1 0 01.865 1.502z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 14V9A6 6 0 006 9v5c0 .729-.195 1.412-.535 2h13.07A3.982 3.982 0 0118 14zm4 4H2c-1.333 0-1.333-2 0-2a2 2 0 002-2V9a8 8 0 1116 0v5a2 2 0 002 2c1.333 0 1.333 2 0 2zm-7.405 3.502a3 3 0 01-5.19 0A1 1 0 0110.27 20h3.46a1 1 0 01.865 1.502z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  trash: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5H3a1 1 0 000 2h1v13a3 3 0 003 3h10a3 3 0 003-3V7h1a1 1 0 100-2h-4V4a3 3 0 00-3-3h-4a3 3 0 00-3 3v1zm2 0h6V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v1zm7 2H6v13a1 1 0 001 1h10a1 1 0 001-1V7h-2zm-7 4v6a1 1 0 102 0v-6a1 1 0 10-2 0zm4 6v-6a1 1 0 112 0v6a1 1 0 11-2 0z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="2" y="1" width="20" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 5H3a1 1 0 000 2h1v13a3 3 0 003 3h10a3 3 0 003-3V7h1a1 1 0 100-2h-4V4a3 3 0 00-3-3h-4a3 3 0 00-3 3v1zm2 0h6V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v1zm7 2H6v13a1 1 0 001 1h10a1 1 0 001-1V7h-2zm-7 4v6a1 1 0 102 0v-6a1 1 0 10-2 0zm4 6v-6a1 1 0 112 0v6a1 1 0 11-2 0z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  plus: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11V5a1 1 0 112 0v6h6a1 1 0 110 2h-6v6a1 1 0 11-2 0v-6H5a1 1 0 110-2h6z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 11V5a1 1 0 112 0v6h6a1 1 0 110 2h-6v6a1 1 0 11-2 0v-6H5a1 1 0 110-2h6z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  link: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.575 15.99A6 6 0 019.2 13.599a1 1 0 011.602-1.198 4 4 0 006.032.432l2.988-2.988a4 4 0 00-.049-5.607 3.997 3.997 0 00-5.597-.059l-1.72 1.71a1 1 0 11-1.41-1.418l1.73-1.72a6 6 0 018.472 8.496l-3 3a6 6 0 01-4.672 1.743zm-3.15-7.98a6 6 0 014.376 2.391 1 1 0 01-1.602 1.198 4 4 0 00-6.032-.432L4.18 14.155a4 4 0 00.049 5.607c1.543 1.543 4.038 1.565 5.595.06l1.71-1.71a1 1 0 011.414 1.415l-1.722 1.722a6 6 0 01-8.472-8.496l3-3a6 6 0 014.672-1.743z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.575 15.99A6 6 0 019.2 13.599a1 1 0 011.602-1.198 4 4 0 006.032.432l2.988-2.988a4 4 0 00-.049-5.607 3.997 3.997 0 00-5.597-.059l-1.72 1.71a1 1 0 11-1.41-1.418l1.73-1.72a6 6 0 018.472 8.496l-3 3a6 6 0 01-4.672 1.743zm-3.15-7.98a6 6 0 014.376 2.391 1 1 0 01-1.602 1.198 4 4 0 00-6.032-.432L4.18 14.155a4 4 0 00.049 5.607c1.543 1.543 4.038 1.565 5.595.06l1.71-1.71a1 1 0 011.414 1.415l-1.722 1.722a6 6 0 01-8.472-8.496l3-3a6 6 0 014.672-1.743z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  document: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1a.997.997 0 011 1v2.414L17.586 7H20a1 1 0 011 1v12a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 013-3h8zM6 3h7l6 6v11a1 1 0 01-1 1H6a1 1 0 01-1-1V4a1 1 0 011-1zm2 9h8a1 1 0 110 2H8a1 1 0 110-2zm8 4H8a1 1 0 100 2h8a1 1 0 100-2zM8 8h2a1 1 0 110 2H8a1 1 0 110-2z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="3" y="1" width="18" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 1a.997.997 0 011 1v2.414L17.586 7H20a1 1 0 011 1v12a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 013-3h8zM6 3h7l6 6v11a1 1 0 01-1 1H6a1 1 0 01-1-1V4a1 1 0 011-1zm2 9h8a1 1 0 110 2H8a1 1 0 110-2zm8 4H8a1 1 0 100 2h8a1 1 0 100-2zM8 8h2a1 1 0 110 2H8a1 1 0 110-2z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  checked: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12.006v-.93a1 1 0 112 0v.93a11 11 0 11-6.523-10.053 1 1 0 01-.814 1.827A9 9 0 1020 12.006zM8.707 10.3L11 12.592 21.293 2.3a1 1 0 111.414 1.414l-11 11a1 1 0 01-1.414 0l-3-3A1 1 0 118.707 10.3z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="1" width="23" height="23">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 12.006v-.93a1 1 0 112 0v.93a11 11 0 11-6.523-10.053 1 1 0 01-.814 1.827A9 9 0 1020 12.006zM8.707 10.3L11 12.592 21.293 2.3a1 1 0 111.414 1.414l-11 11a1 1 0 01-1.414 0l-3-3A1 1 0 118.707 10.3z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  check: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.707 12.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0l11-11a1 1 0 00-1.414-1.414L9 16.586l-4.293-4.293z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="3" y="6" width="18" height="13">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.707 12.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0l11-11a1 1 0 00-1.414-1.414L9 16.586l-4.293-4.293z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  multipleOn: (
    <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7188 1.28957C20.9062 1.49277 21 1.72724 21 1.99297C21 2.2587 20.9062 2.49316 20.7188 2.69637L10.7109 12.7081C10.6016 12.8019 10.4805 12.8722 10.3477 12.9191C10.2148 12.966 10.1016 12.9894 10.0078 12.9894C9.91406 12.9894 9.80078 12.966 9.66797 12.9191C9.53516 12.8722 9.41406 12.8019 9.30469 12.7081L6.30469 9.70692C6.10156 9.50371 6 9.26925 6 9.00352C6 8.73779 6.10156 8.50332 6.30469 8.30012C6.50781 8.09691 6.74219 7.99531 7.00781 7.99531C7.27344 7.99531 7.50781 8.09691 7.71094 8.30012L10.0078 10.5979L19.3125 1.28957C19.5156 1.08636 19.75 0.98476 20.0156 0.98476C20.2813 0.98476 20.5156 1.08636 20.7188 1.28957ZM19.0078 9.00352C19.3047 9.00352 19.5469 9.09339 19.7344 9.27315C19.9219 9.45291 20.0156 9.69128 20.0156 9.98828V16.9988C20.0156 17.8429 19.7266 18.5541 19.1484 19.1325C18.5703 19.7108 17.8594 20 17.0156 20H3C2.15625 20 1.44532 19.7108 0.867188 19.1325C0.28906 18.5541 0 17.8429 0 16.9988V3.00117C0 2.14146 0.28906 1.42634 0.867188 0.855803C1.44532 0.285265 2.15625 0 3 0H14.0156C14.3125 0 14.5508 0.0898779 14.7305 0.269637C14.9102 0.449395 15 0.687767 15 0.98476C15 1.29738 14.9102 1.54357 14.7305 1.72333C14.5508 1.90309 14.3125 1.99297 14.0156 1.99297H3C2.70312 1.99297 2.46484 2.08284 2.28516 2.2626C2.10547 2.44236 2.01562 2.68855 2.01562 3.00117V16.9988C2.01562 17.2958 2.10547 17.5381 2.28516 17.7257C2.46484 17.9132 2.70312 18.007 3 18.007H17.0156C17.3125 18.007 17.5508 17.9132 17.7305 17.7257C17.9102 17.5381 18 17.2958 18 16.9988V9.98828C18 9.69128 18.0938 9.45291 18.2812 9.27315C18.4688 9.09339 18.7109 9.00352 19.0078 9.00352Z"
        fill="#4072DE"
      />
    </svg>
  ),
  multipleOff: (
    <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0223 0C17.8664 0 18.5776 0.285265 19.1559 0.855803C19.7343 1.42634 20.0234 2.14146 20.0234 3.00117V16.9988C20.0234 17.8429 19.7343 18.5541 19.1559 19.1325C18.5776 19.7108 17.8664 20 17.0223 20H3.00117C2.15709 20 1.44588 19.7108 0.867526 19.1325C0.289173 18.5541 0 17.8429 0 16.9988V3.00117C0 2.14146 0.289173 1.42634 0.867526 0.855803C1.44588 0.285265 2.15709 0 3.00117 0H17.0223ZM18.007 16.9988V3.00117C18.007 2.68855 17.9172 2.44236 17.7374 2.2626C17.5576 2.08284 17.3193 1.99297 17.0223 1.99297H3.00117C2.70418 1.99297 2.46581 2.08284 2.28605 2.2626C2.10629 2.44236 2.01641 2.68855 2.01641 3.00117V16.9988C2.01641 17.2958 2.10629 17.5381 2.28605 17.7257C2.46581 17.9132 2.70418 18.007 3.00117 18.007H17.0223C17.3193 18.007 17.5576 17.9132 17.7374 17.7257C17.9172 17.5381 18.007 17.2958 18.007 16.9988Z"
        fill="#D7D8DB"
      />
    </svg>
  ),
  calendar: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2V1a1 1 0 112 0v1h2a3 3 0 013 3v14a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2V1a1 1 0 012 0v1h6zM4 10v9a1 1 0 001 1h14a1 1 0 001-1v-9H4zm16-2H4V5a1 1 0 011-1h2v1a1 1 0 002 0V4h6v1a1 1 0 102 0V4h2a1 1 0 011 1v3z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2V1a1 1 0 112 0v1h2a3 3 0 013 3v14a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2V1a1 1 0 012 0v1h6zM4 10v9a1 1 0 001 1h14a1 1 0 001-1v-9H4zm16-2H4V5a1 1 0 011-1h2v1a1 1 0 002 0V4h6v1a1 1 0 102 0V4h2a1 1 0 011 1v3z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  lock: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10H5a3 3 0 00-3 3v7a3 3 0 003 3h14a3 3 0 003-3v-7a3 3 0 00-3-3h-1V7A6 6 0 006 7v3zm2 0h8V7a4 4 0 00-8 0v3zm9 2h2a1 1 0 011 1v7a1 1 0 01-1 1H5a1 1 0 01-1-1v-7a1 1 0 011-1h12z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="2" y="1" width="20" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 10H5a3 3 0 00-3 3v7a3 3 0 003 3h14a3 3 0 003-3v-7a3 3 0 00-3-3h-1V7A6 6 0 006 7v3zm2 0h8V7a4 4 0 00-8 0v3zm9 2h2a1 1 0 011 1v7a1 1 0 01-1 1H5a1 1 0 01-1-1v-7a1 1 0 011-1h12z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  open: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.414V9a1 1 0 102 0V3.001v-.01V3m-3.414 1l-9.293 9.293a1 1 0 101.414 1.414L20 5.414M17 13v6a1 1 0 01-1 1H5a1 1 0 01-1-1V8a1 1 0 011-1h6a1 1 0 100-2H5a3 3 0 00-3 3v11a3 3 0 003 3h11a3 3 0 003-3v-6a1 1 0 10-2 0z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 5.414V9a1 1 0 102 0V3.001v-.01V3m-3.414 1l-9.293 9.293a1 1 0 101.414 1.414L20 5.414M17 13v6a1 1 0 01-1 1H5a1 1 0 01-1-1V8a1 1 0 011-1h6a1 1 0 100-2H5a3 3 0 00-3 3v11a3 3 0 003 3h11a3 3 0 003-3v-6a1 1 0 10-2 0z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  inbox: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3 3h9.4a3 3 0 012.744 1.784l3.461 7.79c.06.13.095.274.095.426v5a3 3 0 01-3 3H4a3 3 0 01-3-3v-5c0-.152.034-.297.095-.426l3.46-7.79A3 3 0 017.3 3zM3 14v4a1 1 0 001 1h16a1 1 0 001-1v-4h-4.465l-1.703 2.555A1 1 0 0114 17h-4a1 1 0 01-.832-.445L7.465 14H3zm17.461-2H16a1 1 0 00-.832.445L13.465 15h-2.93l-1.703-2.555A1 1 0 008 12H3.539l2.845-6.405A1 1 0 017.3 5H16.7a1 1 0 01.916.595L20.46 12z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="3" width="22" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.3 3h9.4a3 3 0 012.744 1.784l3.461 7.79c.06.13.095.274.095.426v5a3 3 0 01-3 3H4a3 3 0 01-3-3v-5c0-.152.034-.297.095-.426l3.46-7.79A3 3 0 017.3 3zM3 14v4a1 1 0 001 1h16a1 1 0 001-1v-4h-4.465l-1.703 2.555A1 1 0 0114 17h-4a1 1 0 01-.832-.445L7.465 14H3zm17.461-2H16a1 1 0 00-.832.445L13.465 15h-2.93l-1.703-2.555A1 1 0 008 12H3.539l2.845-6.405A1 1 0 017.3 5H16.7a1 1 0 01.916.595L20.46 12z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  map: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.496 22.868l6.535-3.734 7.504 3.751A.994.994 0 0016 23a.994.994 0 00.511-.14l6.985-3.992A1 1 0 0024 18V2a1 1 0 00-1.496-.868l-6.536 3.734-7.503-3.751A.995.995 0 008.007 1H8a.995.995 0 00-.511.14L.504 5.132A1 1 0 000 6v16a1 1 0 001.496.868zM17 20.277l5-2.857V3.723L17 6.58v13.697zM15 6.618l-6-3v13.764l6 3V6.618zM2 6.58l5-2.857V17.42l-5 2.857V6.58z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.496 22.868l6.535-3.734 7.504 3.751A.994.994 0 0016 23a.994.994 0 00.511-.14l6.985-3.992A1 1 0 0024 18V2a1 1 0 00-1.496-.868l-6.536 3.734-7.503-3.751A.995.995 0 008.007 1H8a.995.995 0 00-.511.14L.504 5.132A1 1 0 000 6v16a1 1 0 001.496.868zM17 20.277l5-2.857V3.723L17 6.58v13.697zM15 6.618l-6-3v13.764l6 3V6.618zM2 6.58l5-2.857V17.42l-5 2.857V6.58z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  flag: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 15V3a1 1 0 01.293-.707c.22-.22.614-.483 1.21-.721C5.407 1.21 6.564 1 8 1c1.417 0 2.383.276 4.371 1.072C14.133 2.776 14.917 3 16 3c1.189 0 2.094-.165 2.754-.428.341-.137.508-.249.539-.28C19.923 1.663 21 2.11 21 3v12a1 1 0 01-.293.707c-.22.22-.614.483-1.21.721-.903.362-2.06.572-3.497.572-1.417 0-2.383-.276-4.371-1.072C9.867 15.224 9.083 15 8 15c-1.189 0-2.094.165-2.754.428a4.077 4.077 0 00-.246.108V22a1 1 0 11-2 0v-7zm5-2c1.417 0 2.383.276 4.371 1.072 1.762.704 2.546.928 3.629.928 1.189 0 2.094-.165 2.754-.428.095-.039.177-.075.246-.108v-9.86c-.82.253-1.814.396-3 .396-1.417 0-2.383-.276-4.371-1.072C9.867 3.224 9.083 3 8 3c-1.189 0-2.094.165-2.754.428A4.09 4.09 0 005 3.536v9.86C5.82 13.143 6.814 13 8 13z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="3" y="1" width="18" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 15V3a1 1 0 01.293-.707c.22-.22.614-.483 1.21-.721C5.407 1.21 6.564 1 8 1c1.417 0 2.383.276 4.371 1.072C14.133 2.776 14.917 3 16 3c1.189 0 2.094-.165 2.754-.428.341-.137.508-.249.539-.28C19.923 1.663 21 2.11 21 3v12a1 1 0 01-.293.707c-.22.22-.614.483-1.21.721-.903.362-2.06.572-3.497.572-1.417 0-2.383-.276-4.371-1.072C9.867 15.224 9.083 15 8 15c-1.189 0-2.094.165-2.754.428a4.077 4.077 0 00-.246.108V22a1 1 0 11-2 0v-7zm5-2c1.417 0 2.383.276 4.371 1.072 1.762.704 2.546.928 3.629.928 1.189 0 2.094-.165 2.754-.428.095-.039.177-.075.246-.108v-9.86c-.82.253-1.814.396-3 .396-1.417 0-2.383-.276-4.371-1.072C9.867 3.224 9.083 3 8 3c-1.189 0-2.094.165-2.754.428A4.09 4.09 0 005 3.536v9.86C5.82 13.143 6.814 13 8 13z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  bars: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2h4a1 1 0 011 1v18a1 1 0 01-1 1h-4a1 1 0 01-1-1V3a1 1 0 011-1zm3 2h-2v16h2V4zM10 7h4a1 1 0 011 1v13a1 1 0 01-1 1h-4a1 1 0 01-1-1V8a1 1 0 011-1zm1 13V9h2v11h-2zm-5-8H2a1 1 0 00-1 1v8a1 1 0 001 1h4a1 1 0 001-1v-8a1 1 0 00-1-1zm-3 8h2v-6H3v6z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="2" width="22" height="20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 2h4a1 1 0 011 1v18a1 1 0 01-1 1h-4a1 1 0 01-1-1V3a1 1 0 011-1zm3 2h-2v16h2V4zM10 7h4a1 1 0 011 1v13a1 1 0 01-1 1h-4a1 1 0 01-1-1V8a1 1 0 011-1zm1 13V9h2v11h-2zm-5-8H2a1 1 0 00-1 1v8a1 1 0 001 1h4a1 1 0 001-1v-8a1 1 0 00-1-1zm-3 8h2v-6H3v6z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  pin: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.03 22.735a31.128 31.128 0 003.196-2.922C20.198 16.676 22 13.372 22 10c0-5.523-4.477-10-10-10S2 4.477 2 10c0 3.372 1.802 6.676 4.774 9.813a31.125 31.125 0 004.254 3.726c.195.141.337.24.417.293a1 1 0 001.11 0c.08-.054.222-.152.417-.293.32-.23.675-.5 1.057-.804zm1.744-4.298A29.155 29.155 0 0112 21.773a29.155 29.155 0 01-3.774-3.335C5.573 15.636 4 12.752 4 10a8 8 0 1116 0c0 2.753-1.573 5.636-4.226 8.437zM12 14a4 4 0 110-8 4 4 0 010 8zm2-4a2 2 0 11-4 0 2 2 0 014 0z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="2" y="0" width="20" height="24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.03 22.735a31.128 31.128 0 003.196-2.922C20.198 16.676 22 13.372 22 10c0-5.523-4.477-10-10-10S2 4.477 2 10c0 3.372 1.802 6.676 4.774 9.813a31.125 31.125 0 004.254 3.726c.195.141.337.24.417.293a1 1 0 001.11 0c.08-.054.222-.152.417-.293.32-.23.675-.5 1.057-.804zm1.744-4.298A29.155 29.155 0 0112 21.773a29.155 29.155 0 01-3.774-3.335C5.573 15.636 4 12.752 4 10a8 8 0 1116 0c0 2.753-1.573 5.636-4.226 8.437zM12 14a4 4 0 110-8 4 4 0 010 8zm2-4a2 2 0 11-4 0 2 2 0 014 0z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  phone: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.888 16.92c.013-.532-.357-.969-.85-1.039a13.849 13.849 0 01-3.032-.755.998.998 0 00-1.05.221l-1.27 1.27a1 1 0 01-1.202.162 17 17 0 01-6.375-6.375 1 1 0 01.162-1.201l1.266-1.266a1 1 0 00.224-1.057 13.817 13.817 0 01-.753-3.02A1.003 1.003 0 006.998 3H4a1 1 0 00-.996 1.074 18.8 18.8 0 002.92 8.24 18.512 18.512 0 005.7 5.697 18.775 18.775 0 008.175 2.913 1 1 0 001.09-1.004v-3zm-7.617-1.717l1.201-.162c-.22-.125-.436-.255-.648-.39l-.553.552zm9.617 4.713a3 3 0 01-3.288 2.998 20.779 20.779 0 01-9.058-3.22 20.49 20.49 0 01-6.303-6.3A20.804 20.804 0 011.012 4.27 3 3 0 013.998 1h2.99A3.002 3.002 0 019.99 3.59c.116.885.333 1.754.644 2.588a3.002 3.002 0 01-.679 3.17l-.717.716a15.002 15.002 0 004.586 4.586l.721-.721a2.999 2.999 0 013.163-.676c.836.312 1.706.529 2.6.647a3 3 0 012.58 3.031v2.985z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.888 16.92c.013-.532-.357-.969-.85-1.039a13.849 13.849 0 01-3.032-.755.998.998 0 00-1.05.221l-1.27 1.27a1 1 0 01-1.202.162 17 17 0 01-6.375-6.375 1 1 0 01.162-1.201l1.266-1.266a1 1 0 00.224-1.057 13.817 13.817 0 01-.753-3.02A1.003 1.003 0 006.998 3H4a1 1 0 00-.996 1.074 18.8 18.8 0 002.92 8.24 18.512 18.512 0 005.7 5.697 18.775 18.775 0 008.175 2.913 1 1 0 001.09-1.004v-3zm-7.617-1.717l1.201-.162c-.22-.125-.436-.255-.648-.39l-.553.552zm9.617 4.713a3 3 0 01-3.288 2.998 20.779 20.779 0 01-9.058-3.22 20.49 20.49 0 01-6.303-6.3A20.804 20.804 0 011.012 4.27 3 3 0 013.998 1h2.99A3.002 3.002 0 019.99 3.59c.116.885.333 1.754.644 2.588a3.002 3.002 0 01-.679 3.17l-.717.716a15.002 15.002 0 004.586 4.586l.721-.721a2.999 2.999 0 013.163-.676c.836.312 1.706.529 2.6.647a3 3 0 012.58 3.031v2.985z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  controls: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18.874a4.002 4.002 0 010-7.748V4a1 1 0 012 0v7.126a4.002 4.002 0 010 7.748V20a1 1 0 11-2 0v-1.126zm8-13.748V4a1 1 0 112 0v1.126a4.002 4.002 0 010 7.748V20a1 1 0 11-2 0v-7.126a4.002 4.002 0 010-7.748zM8 17a2 2 0 100-4 2 2 0 000 4zm8-6a2 2 0 100-4 2 2 0 000 4z"
        fill="#494B50"
      />
    </svg>
  ),
  refresh: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9.133c0 .118.021.236.065.348l.004.012c.142.35.486.597.888.597h5.739a.956.956 0 100-1.91H4.37L7.05 5.67a7.677 7.677 0 017.122-2.071 7.647 7.647 0 015.525 4.9.957.957 0 001.804-.636 9.559 9.559 0 00-6.907-6.126 9.578 9.578 0 00-8.876 2.562l-2.805 2.63v-3.52a.956.956 0 00-1.913 0v5.725zm22 5.734a.955.955 0 00-.065-.348l-.004-.012a.957.957 0 00-.887-.597h-5.74a.956.956 0 100 1.91h3.325L16.95 18.33a7.676 7.676 0 01-7.122 2.071 7.647 7.647 0 01-5.525-4.9.957.957 0 00-1.804.636 9.559 9.559 0 006.907 6.127 9.578 9.578 0 008.876-2.563l2.805-2.63v3.52a.956.956 0 001.913 0v-5.725z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 9.133c0 .118.021.236.065.348l.004.012c.142.35.486.597.888.597h5.739a.956.956 0 100-1.91H4.37L7.05 5.67a7.677 7.677 0 017.122-2.071 7.647 7.647 0 015.525 4.9.957.957 0 001.804-.636 9.559 9.559 0 00-6.907-6.126 9.578 9.578 0 00-8.876 2.562l-2.805 2.63v-3.52a.956.956 0 00-1.913 0v5.725zm22 5.734a.955.955 0 00-.065-.348l-.004-.012a.957.957 0 00-.887-.597h-5.74a.956.956 0 100 1.91h3.325L16.95 18.33a7.676 7.676 0 01-7.122 2.071 7.647 7.647 0 01-5.525-4.9.957.957 0 00-1.804.636 9.559 9.559 0 006.907 6.127 9.578 9.578 0 008.876-2.563l2.805-2.63v3.52a.956.956 0 001.913 0v-5.725z"
          fill="#fff"
        />
      </mask>
    </svg>
  ),
  out: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.637 11c.299 0 .534.086.705.258.172.173.258.4.258.685v6.682c0 .809-.276 1.49-.829 2.044-.552.554-1.231.831-2.038.831H6.267c-.807 0-1.486-.277-2.038-.831-.553-.554-.829-1.235-.829-2.044v-6.682c0-.284.086-.512.258-.685.171-.172.406-.258.705-.258.284 0 .511.086.683.258.172.173.258.4.258.685v6.682c0 .285.09.513.268.685.18.172.411.258.695.258h11.466c.284 0 .515-.086.695-.258.179-.172.268-.4.268-.685v-6.682c0-.284.086-.512.258-.685.172-.172.4-.258.683-.258zM8.823 7.454a.929.929 0 01-.677.292.929.929 0 01-.676-.292.967.967 0 01-.27-.677c0-.255.09-.48.27-.676l3.854-3.83a.468.468 0 01.146-.102.468.468 0 00.147-.101A1.49 1.49 0 0112.045 2c.135 0 .248.023.338.068.045 0 .094.022.147.067.052.045.101.09.146.135l3.854 3.831c.18.196.27.421.27.676 0 .256-.09.481-.27.677a1.03 1.03 0 01-.316.225.908.908 0 01-.36.067.971.971 0 01-.384-.067.81.81 0 01-.293-.225L12.97 5.245v9.183c0 .3-.09.537-.27.71-.18.172-.414.259-.699.259-.286 0-.518-.087-.699-.26-.18-.172-.27-.409-.27-.71V5.246L8.823 7.454z"
        fill="#494B50"
      />
    </svg>
  ),
  important: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6a1 1 0 112 0v8a1 1 0 11-2 0V6zm.293 12.707a1 1 0 111.414-1.414 1 1 0 01-1.414 1.414z"
        fill="#494B50"
      />
    </svg>
  ),
  print: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3H7v5h10V3zm2 5V2a1 1 0 00-1-1H6a1 1 0 00-1 1v6H4a3 3 0 00-3 3v5a3 3 0 003 3h1v3a1 1 0 001 1h12a1 1 0 001-1v-3h1a3 3 0 003-3v-5a3 3 0 00-3-3h-1zM5 14v3H4a1 1 0 01-1-1v-5a1 1 0 011-1h16a1 1 0 011 1v5a1 1 0 01-1 1h-1v-3a1 1 0 00-1-1H6a1 1 0 00-1 1zm12 7H7v-6h10v6z"
        fill="#494B50"
      />
    </svg>
  ),
  circle: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.29 1.867C14.96 1.29 13.53 1 12 1c-1.531 0-2.96.29-4.29.867a10.698 10.698 0 00-3.503 2.344c-.992 1-1.777 2.164-2.355 3.492a10.995 10.995 0 00-.844 4.29c0 1.53.281 2.96.844 4.288a11.108 11.108 0 002.355 3.504c.992.992 2.16 1.777 3.504 2.356 1.328.578 2.758.867 4.289.867 1.531 0 2.96-.29 4.29-.867a11.11 11.11 0 003.503-2.356 11.107 11.107 0 002.355-3.504c.563-1.328.844-2.758.844-4.289 0-1.515-.281-2.945-.844-4.289a11.325 11.325 0 00-2.355-3.492c-.992-1-2.16-1.781-3.504-2.344zm-.774 18.422a8.83 8.83 0 01-3.516.703 8.83 8.83 0 01-3.516-.703 9.03 9.03 0 01-2.859-1.922 9.03 9.03 0 01-1.922-2.86A8.83 8.83 0 013 11.993c0-1.25.234-2.422.703-3.515a9.03 9.03 0 011.922-2.86 9.03 9.03 0 012.86-1.922A8.83 8.83 0 0112 2.992a8.83 8.83 0 013.516.703 9.03 9.03 0 012.859 1.922 9.03 9.03 0 011.922 2.86A8.83 8.83 0 0121 11.992a8.83 8.83 0 01-.703 3.516 9.03 9.03 0 01-1.922 2.86 9.03 9.03 0 01-2.86 1.921z"
        fill="#C7CAD1"
      />
    </svg>
  ),
  error: (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0ZM7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1ZM7 2.91667C6.67783 2.91667 6.41667 3.17783 6.41667 3.5V8.16667C6.41667 8.48883 6.67783 8.75 7 8.75C7.32217 8.75 7.58333 8.48883 7.58333 8.16667V3.5C7.58333 3.17783 7.32217 2.91667 7 2.91667ZM6.58752 10.0875C6.35971 10.3153 6.35971 10.6847 6.58752 10.9125C6.81533 11.1403 7.18467 11.1403 7.41248 10.9125C7.64028 10.6847 7.64028 10.3153 7.41248 10.0875C7.18467 9.85972 6.81533 9.85972 6.58752 10.0875Z"
        fill="#A31D18"
      />
    </svg>
  )
};

export default icons;

export type IconTypes = keyof typeof icons;
