import React from "react";

type PropsType = {
  size?: number;
};

const DoubleChevronLeft = ({ size = 13 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size * (11 / 13)}px`}
      viewBox="0 0 13 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(0,0.25)"
        d="M5.78.22a.75.75 0 00-1.06 0l-4.5 4.5a.75.75 0 000 1.06l4.5 4.5a.75.75 0 001.06-1.06L1.81 5.25l3.97-3.97a.75.75 0 000-1.06zm6.75 0a.75.75 0 00-1.06 0l-4.5 4.5a.75.75 0 000 1.06l4.5 4.5a.75.75 0 001.06-1.06L8.56 5.25l3.97-3.97a.75.75 0 000-1.06z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default DoubleChevronLeft;
