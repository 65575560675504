import React, { useCallback } from "react";

import axios from "axios";

import getToken from "../utils/getToken";

import { ROOT_URL, UserTypeConstants } from "../constants";

export enum AppMetricType {
  APPLICATION_ERROR = "applicationError"
}

type BaseAppMetric = {
  type: AppMetricType;
};

type ErrorMetric = BaseAppMetric & {
  type: AppMetricType.APPLICATION_ERROR;
  errorMessage: string;
  errorInfo: string;
  userAgent: string;
};

export type AppMetric = ErrorMetric;

export type AppMetricWithContext = AppMetric & {
  timestamp: string; // ISO date string
  organizationId?: number;
  userId?: number;
  userType?:
    | typeof UserTypeConstants.MIKATA_ADMIN
    | typeof UserTypeConstants.STAFF
    | typeof UserTypeConstants.PRACTITIONER;
};

export type QueueMetricOptions = {
  postImmediately?: boolean;
};

const MAX_METRICS_QUEUE_LENGTH = 100;

const getMetricsQueue = (): AppMetricWithContext[] => {
  const metricsQueueString = localStorage.getItem("metricsQueue");
  const metricsQueue: AppMetricWithContext[] = metricsQueueString
    ? JSON.parse(metricsQueueString)
    : [];
  return metricsQueue;
};

const setMetricsQueue = (metricQueue: AppMetric[]): void => {
  const metricsQueueString = JSON.stringify(metricQueue);

  localStorage.setItem("metricsQueue", metricsQueueString);
};

export const saveAppMetrics = async (appMetrics: AppMetricWithContext[]) => {
  const config = {
    headers: { Authorization: getToken(), "Content-Type": "application/json" }
  };
  const data = { metrics: appMetrics };

  try {
    await axios.post(`${ROOT_URL}/analytics/patient-application`, data, config);
  } catch (error) {
    console.error(error);
  }
};

type PropsType = {
  organizationId?: number;
  userId?: number;
};

const useAppAnalytics = ({ organizationId, userId }: PropsType) => {
  const processMetricsQueue = (): void => {
    const metricsQueue = getMetricsQueue();
    const isOnline = !window.navigator || window.navigator?.onLine;
    const isLoggedIn = Boolean(sessionStorage.getItem("token"));

    if (isOnline && isLoggedIn && metricsQueue && metricsQueue.length > 0) {
      saveAppMetrics(metricsQueue);
      setMetricsQueue([]);
    }
  };

  const queueMetric = useCallback(
    (appMetric: AppMetric, options?: QueueMetricOptions) => {
      const { postImmediately = false } = options || {};
      const appMetricWithContext = {
        ...appMetric,
        organizationId,
        userId,
        timestamp: new Date().toISOString()
      };

      // Push app metric to local storage queue
      const currentMetricsQueue = getMetricsQueue();

      // Protect against memory leaks
      if (currentMetricsQueue?.length >= MAX_METRICS_QUEUE_LENGTH) {
        // Discard oldest queued metric
        currentMetricsQueue.shift();
      }

      const updatedMetricQueue = [...currentMetricsQueue, appMetricWithContext];
      setMetricsQueue(updatedMetricQueue);

      if (postImmediately) {
        processMetricsQueue();
      }
    },
    [organizationId, userId]
  );

  return { queueMetric, processMetricsQueue };
};

export default useAppAnalytics;
