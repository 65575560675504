import React from "react";
import { connect } from "react-redux";

import Node, { JsonContentPlaceholderContext } from "./Node";

import { JsonContent, JsonContentNode } from "../../types";

type PropsType = {
  content: JsonContent;
  placeholderContext?: JsonContentPlaceholderContext;
  contentBoxShadow?: boolean;
  contentPadding?: string;
  contentMargin?: string;
  showInfoModalButton?: boolean;
};

const defaultPlaceholderContext = {
  locationNumber: ""
};

const JsonContentRenderEngine = ({
  content,
  placeholderContext = defaultPlaceholderContext,
  contentBoxShadow = true,
  contentPadding,
  contentMargin = "0 0 32px 0",
  showInfoModalButton = true
}: PropsType): JSX.Element => {
  return (
    <>
      {content &&
        content.map((jsonContentNode: JsonContentNode) => {
          return (
            <Node
              key={jsonContentNode.id}
              node={jsonContentNode}
              typePath={[jsonContentNode.type]}
              placeholderContext={placeholderContext}
              contentBoxShadow={contentBoxShadow}
              contentPadding={contentPadding}
              contentMargin={contentMargin}
              showInfoModalButton={showInfoModalButton}
            />
          );
        })}
    </>
  );
};

export default JsonContentRenderEngine;
