/* AnimationPlayer
======================================= */
import React, { useEffect, useRef } from "react";
import cx from "classnames";
import lottieLight, { AnimationItem } from "lottie-web/build/player/lottie_light";

import {
  checkIn,
  progressBar,
  scribeWaiting,
  scribeStreaming,
  scribeReconnecting,
  scribeCompleted,
  starFace
} from "./variants";

import styles from "./index.module.scss";

export enum AnimationVariant {
  CHECK_IN = "checkIn",
  MIKA_PROGRESS_CHARACTER = "mikaProgressCharacter",
  SCRIBE_WAITING = "scribeWaiting",
  SCRIBE_STREAMING = "scribeStreaming",
  SCRIBE_RECONNECTING = "scribeReconnecting",
  SCRIBE_COMPLETED = "scribeCompleted",
  STAR_FACE = "starFace"
}

type PropsType = {
  className?: string;
  variant?: AnimationVariant;
  configurationOptions?: any;
};

const animationVariantsMap: { [variant: string]: any } = {
  [AnimationVariant.CHECK_IN as string]: checkIn,
  [AnimationVariant.MIKA_PROGRESS_CHARACTER as string]: progressBar,
  [AnimationVariant.SCRIBE_WAITING as string]: scribeWaiting,
  [AnimationVariant.SCRIBE_STREAMING as string]: scribeStreaming,
  [AnimationVariant.SCRIBE_RECONNECTING as string]: scribeReconnecting,
  [AnimationVariant.SCRIBE_COMPLETED as string]: scribeCompleted,
  [AnimationVariant.STAR_FACE as string]: starFace
};

const AnimationPlayer = ({ className, configurationOptions, variant }: PropsType) => {
  const containerElementRef = useRef<Element>();
  const animationRef = useRef<AnimationItem>();

  useEffect(() => {
    if (containerElementRef.current) {
      const animationData = animationVariantsMap[variant as string] || undefined;
      if (animationData) {
        animationRef.current = lottieLight.loadAnimation({
          renderer: "svg",
          container: containerElementRef.current,
          name: variant,
          animationData,
          ...configurationOptions
        });
        if (configurationOptions.segments) {
          animationRef.current.playSegments(configurationOptions.segments, true);
        }
      }
    }

    // Clean up on unmounting
    return () => {
      if (animationRef.current) {
        animationRef.current.destroy();
      }
    };
    // Must watch for every configurationOption Property to avoid reloading of animation
  }, [
    !!containerElementRef?.current,
    configurationOptions?.loop,
    configurationOptions?.play,
    configurationOptions?.isPaused,
    variant
  ]);

  return (
    <div
      ref={(ref) => {
        if (ref) {
          containerElementRef.current = ref;
        }
      }}
      className={cx(styles.Wrapper, className)}
    />
  );
};

export default AnimationPlayer;
