/* Theme
========================================= */

import typography from "./typography";
import colors from "./colors";
import animation from "./animation";

const themeName = "default";

/* space
-------------------- */

const space = [
  "0",
  "4px",
  "8px",
  "16px",
  "24px",
  "32px",
  "40px",
  "48px",
  "64px",
  "80px",
  "96px",
  "112px",
  "128px"
];

/* breakpoints
-------------------- */

const breakpoints = ["544px", "768px", "1024px", "1280px", "1920px", "2080px"];

/* borders
-------------------- */

const borders = {
  none: 0,
  "1px": "1px solid",
  "2px": "2px solid"
};

/* shadows
-------------------- */

const shadows = {
  small: "0px 1px 4px rgba(0, 0, 0, 0.0779338)",
  medium: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  large: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xlarge: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)"
};

/* rounded 
-------------------- */

const radii = {
  none: "0",
  normal: "10px",
  full: "100px"
};

/* buttons
-------------------- */

const buttons = {
  primary: {
    color: colors.white,
    bg: colors.black,
    hover: colors.primary.blue,
    rounded: radii.full,
    height: "48px"
  },
  secondary: {
    color: colors.black,
    bg: "transparent",
    hover: colors.primary.blue,
    rounded: radii.full,
    height: "48px"
  },
  text: {
    color: colors.primary.blue,
    bg: "transparent",
    hover: colors.primary.dark
  },
  small: {
    height: "32px"
  }
};

/* theme
-------------------- */

const apptReqHeaderText = "Request your appointment";
const apptReqHeaderSubtext = "";

const theme = {
  ...typography,
  themeName,
  colors,
  borders,
  radii,
  shadows,
  space,
  breakpoints,
  buttons,
  animation,
  apptReqHeaderText,
  apptReqHeaderSubtext
};

export default theme;
