import { ChatFlowTags, ConversationStatuses, StartCardVariants } from "../constants";

/**
 * Task Conversations Ordering
 * (1st) Secure DPM are always at the top
 * (2nd) All incomplete Priority Tasks
 * (3rd) All incomplete Non-Priority Tasks
 * (4th) All Completed Tasks, regardless of Priority or not
 */

export const isConversationStartable = (conversation: Conversation) => {
  return !conversation.isConversationComplete;
};

export const isConversationComplete = (conversation: Conversation) => {
  return (
    conversation?.status &&
    (conversation.status === ConversationStatuses.FLAGGED ||
      conversation.status === ConversationStatuses.COMPLETE)
  );
};

export const getSortedConversations = (conversations: Conversation[]) => {
  const cardConversations = conversations
    ?.filter(
      (conversation) =>
        conversation.startMessage &&
        (!conversation.startMessage.payloadOptions ||
          (conversation.startMessage.payloadOptions as BasePayloadOptions).variant ===
            StartCardVariants.CARD ||
          !(conversation.startMessage.payloadOptions as BasePayloadOptions).variant)
    )
    .sort((conversationA, conversationB) => {
      if (conversationA.isConversationComplete === conversationB.isConversationComplete)
        return conversationA.chatFlowTitle.localeCompare(conversationB.chatFlowTitle);
      return conversationA.isConversationComplete < conversationB.isConversationComplete ? -1 : 1;
    });

  const taskConversations = conversations
    ?.filter(
      (conversation) =>
        conversation.startMessage &&
        conversation.startMessage.payloadOptions &&
        [StartCardVariants.TASK, StartCardVariants.PRIORITY_TASK].includes(
          (conversation.startMessage.payloadOptions as BasePayloadOptions).variant as any
        )
    )
    .sort((conversationA, conversationB) => {
      const variantA = (conversationA.startMessage?.payloadOptions as BasePayloadOptions).variant;
      const variantB = (conversationB.startMessage?.payloadOptions as BasePayloadOptions).variant;

      const bothConversationsArePriority =
        variantA === StartCardVariants.PRIORITY_TASK &&
        variantB === StartCardVariants.PRIORITY_TASK;

      const completedConversationA = isConversationComplete(conversationA);
      const completedConversationB = isConversationComplete(conversationB);

      if (completedConversationA && !completedConversationB) return 1;
      if (!completedConversationA && completedConversationB) return -1;

      if (
        !completedConversationA &&
        conversationA.chatFlowTags.includes(ChatFlowTags.SECURE_DIRECT_MESSAGE) &&
        !conversationB.chatFlowTags.includes(ChatFlowTags.SECURE_DIRECT_MESSAGE) &&
        !completedConversationB
      ) {
        return -1;
      }

      if (
        !completedConversationB &&
        conversationB.chatFlowTags.includes(ChatFlowTags.SECURE_DIRECT_MESSAGE) &&
        !conversationA.chatFlowTags.includes(ChatFlowTags.SECURE_DIRECT_MESSAGE) &&
        !completedConversationA
      ) {
        return 1;
      }

      if (
        variantA === StartCardVariants.PRIORITY_TASK &&
        variantB !== StartCardVariants.PRIORITY_TASK &&
        !completedConversationA &&
        !completedConversationB
      ) {
        return -1;
      }
      if (
        variantA !== StartCardVariants.PRIORITY_TASK &&
        variantB === StartCardVariants.PRIORITY_TASK &&
        !completedConversationB &&
        !completedConversationA
      ) {
        return 1;
      }

      if (
        (bothConversationsArePriority && !completedConversationA && !completedConversationB) ||
        (completedConversationA && completedConversationB)
      ) {
        return conversationA.chatFlowTitle.localeCompare(conversationB.chatFlowTitle);
      }

      if (!bothConversationsArePriority && !completedConversationA && !completedConversationB) {
        return conversationA.chatFlowTitle.localeCompare(conversationB.chatFlowTitle);
      }

      return 0;
    });

  return { cardConversations, taskConversations };
};
