import React from "react";

const ExceptionGraphic = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1066.67 800">
      <path
        d="M300.71 490c-7.28 0-13.67-5.31-14.81-12.73-8.88-57.85-39.93-105.24-64.41-134.81-26.95-32.55-52.16-51.36-52.41-51.54-6.65-4.93-8.06-14.32-3.15-20.97 4.92-6.66 14.3-8.08 20.96-3.17 1.13.83 27.91 20.73 57.13 55.87 38.89 46.76 63.62 98.66 71.52 150.07 1.26 8.19-4.36 15.85-12.55 17.1-.77.12-1.54.18-2.3.18Z"
        fill="#e2faf3"
      />
      <path
        d="M299.64 473.67c-.95 0-1.79-.67-1.96-1.64-.19-1.05-20.1-105.59-82.99-157.17-.85-.7-.98-1.96-.28-2.81.7-.85 1.96-.98 2.81-.28 33.5 27.48 54.66 69.41 66.52 99.75 12.82 32.8 17.82 59.53 17.87 59.79a2 2 0 0 1-1.6 2.33c-.12.02-.24.03-.37.03Z"
        fill="#053125"
      />
      <path
        d="M311.91 559.24c0-17.84-11.96-32.87-28.3-37.55.41-2.72.62-5.51.62-8.34 0-30.81-24.98-55.79-55.79-55.79a55.54 55.54 0 0 0-30.47 9.06c-6.23-10.09-17.38-16.81-30.11-16.81-19.54 0-35.37 15.84-35.37 35.37s15.84 35.37 35.37 35.37c1.77 0 3.52-.13 5.22-.39 3.37 27.59 26.86 48.97 55.36 48.97 2.21 0 4.39-.14 6.53-.39 4.25 16.97 19.6 29.55 37.89 29.55.27 0 .55-.02.82-.02 1.37 8.97.87 13.78.87 13.84l.03-.23 29.7 4.27c.28-1.93 1.45-12.44-2.46-30.69 6.28-6.93 10.11-16.13 10.11-26.22Z"
        fill="#679186"
      />
      <path
        d="M292.07 620.49c-.1 0-.19 0-.29-.02a1.996 1.996 0 0 1-1.69-2.26c.03-.23 3.11-22.92-8.28-49.4-10.51-24.42-35.88-57.2-97.06-74.65a1.991 1.991 0 0 1-1.37-2.47c.3-1.06 1.41-1.68 2.47-1.37 34.67 9.89 80.11 31.3 99.72 77.11 11.76 27.48 8.62 50.39 8.48 51.35-.14 1-1 1.71-1.98 1.71Z"
        fill="#053125"
      />
      <path
        d="M474.33 398.02c0-17.91-14.52-32.43-32.43-32.43-12.44 0-23.24 7.01-28.68 17.29a58.456 58.456 0 0 0-19.21-3.24c-32.36 0-58.59 26.23-58.59 58.59 0 5.9.88 11.6 2.51 16.97-34.66 1.66-62.25 30.29-62.25 65.36 0 22.21 11.07 41.82 27.99 53.65-4.53 18.09-3.01 39.95-2.95 40.89.57 7.89 7.15 13.91 14.94 13.91.36 0 .72-.01 1.09-.04 8.26-.59 14.47-7.77 13.89-16.02-.32-4.52-.58-17.1 1.3-27.6 3 .42 6.06.65 9.18.65 36.14 0 65.44-29.3 65.44-65.44 0-8.59-1.67-16.78-4.68-24.29 28.63-3.85 50.71-28.36 50.71-58.04 0-3.18-.26-6.3-.75-9.34 13.04-4.2 22.48-16.43 22.48-30.87Z"
        fill="#deede8"
      />
      <path
        d="M314.86 618.1a2 2 0 0 1-2-1.94c0-.31-.83-31.15 9.83-70.14 9.85-36.02 31.79-86.62 80.39-123.13.88-.66 2.14-.49 2.8.4.66.88.49 2.14-.4 2.8-47.65 35.8-69.21 85.49-78.9 120.87-10.53 38.43-9.73 68.78-9.72 69.08a2 2 0 0 1-1.94 2.06h-.06Z"
        fill="#053125"
      />
      <path
        d="M348.04 751.48h-91.49a7.26 7.26 0 0 1-7.26-7.26l-15.5-124.94a7.26 7.26 0 0 1 7.26-7.26h124.94a7.26 7.26 0 0 1 7.26 7.26L355.3 744.22a7.26 7.26 0 0 1-7.26 7.26Z"
        fill="#f2f2f3"
      />
      <path
        d="M265.08 656.12h-7.05c-1.1 0-2-.9-2-2s.9-2 2-2h7.05c1.1 0 2 .9 2 2s-.9 2-2 2ZM276.24 668.17h-7.05c-1.1 0-2-.9-2-2s.9-2 2-2h7.05c1.1 0 2 .9 2 2s-.9 2-2 2ZM274.48 686.1h-7.35c-1.1 0-2-.9-2-2s.9-2 2-2h7.35c1.1 0 2 .9 2 2s-.9 2-2 2ZM259.49 675.81h-7.35c-1.1 0-2-.9-2-2s.9-2 2-2h7.35c1.1 0 2 .9 2 2s-.9 2-2 2ZM269.19 716.66h-6.17c-1.1 0-2-.9-2-2s.9-2 2-2h6.17c1.1 0 2 .9 2 2s-.9 2-2 2ZM293.58 724.89h-7.35c-1.1 0-2-.9-2-2s.9-2 2-2h7.35c1.1 0 2 .9 2 2s-.9 2-2 2ZM276.24 731.94h-7.05c-1.1 0-2-.9-2-2s.9-2 2-2h7.05c1.1 0 2 .9 2 2s-.9 2-2 2ZM301.52 741.34h-6.76c-1.1 0-2-.9-2-2s.9-2 2-2h6.76c1.1 0 2 .9 2 2s-.9 2-2 2Z"
        fill="#053125"
      />
      <path
        d="M576 681.22v37.27s18.66 10.74 37.33-.23v-38.61s-20.51-9.69-37.33 1.57Z"
        fill="#ab96e8"
      />
      <path
        d="M574.96 716.98v10.11s-3.28 19.8-.57 23.08c2.71 3.28 10.97 2.99 10.97 2.99h72.94s10.83-17.24-44.02-34.9v-2.71c0-1.38-1.3-2.37-2.63-2.02-5.51 1.47-20.84 4.27-34.16 1.41a2.09 2.09 0 0 0-2.53 2.04Z"
        fill="#826502"
      />
      <path
        d="M491.8 682.26v39.52s8.78 8.7 37.12 2.9l5.76-44.39s-23.22-12.74-42.88 1.96Z"
        fill="#ab96e8"
      />
      <path
        d="M494.35 718.92a4.93 4.93 0 0 0-4.77.97c-3.77 3.27-11.15 10.47-14.19 19.05-4.16 11.76 3.87 14.34 3.87 14.34h45.56s11.76.71 4.3-25.81c0 0 .83-2.91 1.11-5.78a2.078 2.078 0 0 0-2.6-2.22c-15.05 3.95-28.29 1.08-33.28-.55Z"
        fill="#826502"
      />
      <path
        d="M534.94 81.15c-32.22 0-89.6 43.29-42.79 43.29-20.91 8.91-64.43 57.89 6.54 63.43 0 0-27.69 29.7 40.77 36.75l20.14-82.05s38.76 16.61 42.28-16.11-46.31-45.3-66.95-45.3Z"
        fill="#ab96e8"
      />
      <path
        d="M573.01 124.61s20.72 59.09-9.51 67.58v17.66l25.13 10.53s-22.75 36.68-87.28 1.36l18.34-11.21-.34-16.98s-40.41-98.82 53.66-68.94Z"
        fill="#ab96e8"
      />
      <path d="M577.2 141.78s16.93 53.56-26.69 53.56" fill="#ab96e8" />
      <path
        d="M550.51 197.34c-1.1 0-2-.9-2-2s.9-2 2-2c10.33 0 17.89-3.16 22.47-9.4 10.66-14.54 2.4-41.29 2.31-41.56a2.003 2.003 0 0 1 3.82-1.21c.09.29 2.27 7.26 3.05 16.27 1.07 12.3-.93 22-5.95 28.85-5.37 7.33-14.02 11.04-25.7 11.04Z"
        fill="#053125"
      />
      <path fill="#ab96e8" d="m556.11 149.87-9.35 3.67" />
      <path
        d="M546.76 155.54c-.8 0-1.55-.48-1.86-1.27-.4-1.03.1-2.19 1.13-2.59l9.34-3.67c1.03-.41 2.19.1 2.59 1.13.4 1.03-.1 2.19-1.13 2.59l-9.34 3.67a2.1 2.1 0 0 1-.73.14Z"
        fill="#053125"
      />
      <path fill="#ab96e8" d="m574.19 154.06-9.04-4.19" />
      <path
        d="M574.19 156.06c-.28 0-.57-.06-.84-.19l-9.04-4.19c-1-.46-1.44-1.65-.97-2.66.46-1 1.65-1.44 2.66-.97l9.04 4.19c1 .46 1.44 1.65.97 2.66-.34.73-1.06 1.16-1.82 1.16Z"
        fill="#053125"
      />
      <path
        d="M603.79 307.09s.42 50.61 19.24 59.98c26.42 13.14 54.94-81.45 54.94-81.45-4.55-10.41-24.72-8.59-26.99-8.26s-19.52 33.51-19.52 33.51L629 296.75s-24.2 3.06-25.21 10.35Z"
        fill="#ab96e8"
      />
      <path
        d="M512.19 215.12s-33.66 17.98-40.71 24.42c-6.54 5.98-13.71 7.11-20.83 61.21 0 0 23.87-2.03 43.68 7.87 0 0-19.3 120.13-21.84 138.92 0 0 4.45-12.59 66.03-5.1 50.11 6.1 67.3 5.6 82.79-8.62l-17.52-126.73s6.35-7.37 25.91-6.1c0 0-7.87-51.05-16-59.68-8.13-8.64-18.91-21.25-35.61-25.35 0 0-18.33 35.43-65.9-.84Z"
        fill="#fdebd4"
      />
      <path
        d="M614.99 388.45c-.97 0-1.82-.71-1.97-1.69l-14.56-93.55a2 2 0 0 1 1.67-2.28c1.09-.16 2.11.58 2.28 1.67l14.56 93.55a2 2 0 0 1-1.67 2.28c-.1.02-.21.02-.31.02ZM583.61 436.12c-2.47 0-5.06-.15-7.71-.51-13.56-1.86-39.57-4.55-39.83-4.58a1.99 1.99 0 0 1-1.78-2.19c.11-1.1 1.09-1.91 2.19-1.78.26.03 26.34 2.72 39.97 4.59 13.09 1.8 25.12-2.17 25.24-2.21 1.05-.35 2.18.21 2.53 1.26.35 1.05-.21 2.18-1.26 2.53-.43.14-8.73 2.89-19.35 2.89Z"
        fill="#053125"
      />
      <path
        d="m625.78 656.86-6.27-212.36c-.16-5.36-4.55-9.57-9.81-9.42l-130.69 2c-5.79-.11-10.49 4.62-10.34 10.41.96 35.79 3.91 153.03 2.74 211.64-.37 18.81 16.5 34.38 37.69 34.8 21.18.42 38.66-14.48 39.03-33.29l8.42-80.89c.87 29.58 1.71 58.09 2.33 79.08.55 18.8 15.98 33.59 34.46 33.05 18.47-.55 33-16.23 32.44-35.03Z"
        fill="#ffd440"
      />
      <rect x="477.62" y="454.69" width="4.81" height="103.4" rx="2.41" ry="2.41" fill="#fef3e6" />
      <rect x="477.62" y="566.22" width="4.81" height="22.11" rx="2.41" ry="2.41" fill="#fef3e6" />
      <rect x="614.94" y="611" width="4.81" height="23.76" rx="2.41" ry="2.41" fill="#fef3e6" />
      <path
        d="M617.35 643.85c-1.33 0-2.41 1.08-2.41 2.41 0 0 .22 10.55-1.08 16.29-.91 4.03-4.87 12.83-4.87 12.83-.52 1.22.04 2.63 1.26 3.16 1.22.52 2.63-.04 3.16-1.26 0 0 4.37-9.11 5.25-13.47.82-4.07 1.09-17.54 1.09-17.54 0-1.33-1.08-2.41-2.41-2.41Z"
        fill="#fef3e6"
      />
      <path
        d="M549.21 637.29c-.08 0-.15 0-.23-.01a1.998 1.998 0 0 1-1.76-2.21l13.21-115.12a2.01 2.01 0 0 1 2.21-1.76c1.1.13 1.89 1.12 1.76 2.21l-13.21 115.12a1.998 1.998 0 0 1-1.98 1.77Z"
        fill="#053125"
      />
      <rect
        x="636.12"
        y="178.83"
        width="44"
        height="85.95"
        rx="8.83"
        ry="8.83"
        transform="rotate(8.95 658.355 221.917)"
        fill="#194c74"
      />
      <rect
        x="646.29"
        y="180.85"
        width="8.03"
        height="16.91"
        rx="4.01"
        ry="4.01"
        transform="rotate(8.95 650.541 189.425)"
        fill="#1c263c"
      />
      <circle cx="650.86" cy="185.44" r="2.12" fill="#4e6eaf" />
      <circle cx="649.77" cy="192.65" r="2.12" fill="#4e6eaf" />
      <path
        d="M662.78 286.65s-25.94-6.38-10.84-39.12c0 0-11.19-24.93-4.72-28.06 4.36-2.12 11.74 16.58 11.74 16.58s-10.73-23.88-5.95-25.51c5.62-1.92 14.24 22.11 14.24 22.11s-11.8-27.7-6.8-29.77c5.71-2.36 14.24 24.24 14.24 24.24s-8.72-21.47-4.04-22.54c4.68-1.06 16.58 36.78 16.58 36.78s6.38 12.97-1.49 23.6c-5.21 8.5-5.01 9.5-5.01 9.5s-1.92 13.91-5.41 14.3c-4.1.46-12.54-2.12-12.54-2.12ZM450.65 300.74s-18.28 63.48 9.04 104.8l39.03 67.02 21.47-17.57-33.09-101.17 7.23-45.21s-15.3-9.22-43.68-7.87ZM483.97 347.36l20.49 61.2"
        fill="#ab96e8"
      />
      <path
        d="M504.46 410.56c-.84 0-1.62-.53-1.9-1.37l-20.49-61.2c-.35-1.05.21-2.18 1.26-2.53 1.05-.35 2.18.21 2.53 1.26l20.49 61.2a1.995 1.995 0 0 1-1.9 2.63ZM494.21 479.58c-.56 0-1.12-.23-1.51-.69-.72-.84-.63-2.1.21-2.82l29.66-25.61c.84-.72 2.1-.63 2.82.21.72.84.63 2.1-.21 2.82l-29.66 25.61c-.38.33-.84.49-1.31.49Z"
        fill="#053125"
      />
      <circle cx="776.57" cy="611" r="19.64" fill="#5df5c9" />
      <path
        d="M842.06 67.01c1.9-3.28 6.64-3.28 8.53 0l17.07 29.56c1.9 3.28-.47 7.39-4.27 7.39h-34.13c-3.79 0-6.16-4.11-4.27-7.39l17.07-29.56Z"
        fill="#ff878f"
      />
      <rect x="292.74" y="162.81" width="25.88" height="25.88" rx="4" ry="4" fill="#ffd440" />
      <path
        d="M776.57 260.68c-.85 0-1.64-.55-1.91-1.4-7.04-22.54-21.52-37.84-43.02-45.48-16.16-5.74-30.46-4.76-30.6-4.75-1.09.09-2.06-.74-2.14-1.85-.08-1.1.74-2.06 1.84-2.14.61-.05 15.16-1.06 32.1 4.92 15.7 5.54 36.36 18.38 45.65 48.1.33 1.05-.26 2.18-1.31 2.51-.2.06-.4.09-.6.09Z"
        fill="#053125"
      />
      <g fill="#053125">
        <path d="M789.72 384.81c-12.15 0-23.96-3.79-34-11.05-12.63-9.12-20.95-22.6-23.43-37.98-5.12-31.74 16.53-61.73 48.27-66.85 15.37-2.48 30.79 1.17 43.42 10.29 12.63 9.12 20.95 22.6 23.43 37.98 2.48 15.37-1.17 30.79-10.29 43.42s-22.6 20.95-37.98 23.43c-3.15.51-6.29.76-9.42.76Zm.24-112.64c-2.91 0-5.84.23-8.77.71-29.56 4.77-49.73 32.71-44.95 62.27 2.31 14.32 10.06 26.88 21.82 35.37 11.76 8.49 26.12 11.9 40.44 9.58 14.32-2.31 26.88-10.06 35.37-21.82 8.49-11.76 11.89-26.12 9.58-40.44-2.31-14.32-10.06-26.88-21.82-35.37-9.35-6.75-20.36-10.29-31.67-10.29Z" />
        <path
          d="M785.54 300.66a4.31 4.31 0 0 1 8.62 0v34.44a4.31 4.31 0 0 1-8.62 0v-34.44Zm1.26 54.71a4.31 4.31 0 0 1 0-6.09 4.31 4.31 0 0 1 6.09 0 4.31 4.31 0 0 1 0 6.09 4.31 4.31 0 0 1-6.09 0Z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default ExceptionGraphic;
