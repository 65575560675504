import React, { useEffect, useRef } from "react";

const useInterval = (onInterval: () => void, deps: React.DependencyList, intervalMS: number) => {
  const intervalTimer = useRef<NodeJS.Timeout>();
  const reset = () => {
    intervalTimer.current = setInterval(onInterval, intervalMS);
  };

  // Start interval, restart interval when any of the deps change.
  useEffect(() => {
    reset();

    return () => {
      if (intervalTimer.current) {
        clearInterval(intervalTimer.current);
      }
    };
  }, deps);

  return { reset };
};

export default useInterval;
