import axios from "axios";
import { ActionStatus } from "../types";

import { ROOT_URL, NotificationTypes, SEND_REFRESH_LINK } from "../constants";

import { showNotificationOverlay } from "./notifications-overlay";
import { Dispatch } from "../types/actions";

export interface SendRefreshLinkActionData {
  shortCode: string;
}

export const sendRefreshLink =
  (params: SendRefreshLinkActionData) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: SEND_REFRESH_LINK,
        status: ActionStatus.loading
      });

      const shortCode = params?.shortCode?.replaceAll(".", "");

      const payload = await axios.post(
        `${ROOT_URL}/messages/refresh-link`,
        { ...params, shortCode },
        {
          headers: { "Content-Type": "application/json" }
        }
      );

      console.log("payload response in sendRefreshLink action: ", payload);

      if (!payload || !payload.data.success) {
        return dispatch({
          type: SEND_REFRESH_LINK,
          status: ActionStatus.error
        });
      }

      dispatch({
        type: SEND_REFRESH_LINK,
        status: ActionStatus.success
      });

      return dispatch(showNotificationOverlay(NotificationTypes.LINK_RESENT));
    } catch (error: any) {
      const errorStatusCode = (error as any)?.response?.status;

      if (errorStatusCode === 401) {
        return dispatch(showNotificationOverlay(NotificationTypes.LINK_EXPIRED));
      }

      return dispatch({
        type: SEND_REFRESH_LINK,
        status: ActionStatus.error
      });
    }
  };
