import { ChatCardTypes } from "../constants";

export const getLastReceivedMessage = (messages: Message[]): Message | undefined => {
  if (!messages || messages.length === 0) {
    return undefined;
  }

  return [...messages].reverse().find((message) => {
    return Boolean(message.id) && message.sender === "mikata";
  });
};

export const getLastWithResponseByMessageId = (
  messages: Message[],
  messageTemplateId: number
): Message | undefined => {
  if (!messages || messages.length === 0 || !messageTemplateId) {
    return undefined;
  }

  return [...messages].reverse().find((message) => {
    return (
      Boolean(message.id) &&
      message.id === messageTemplateId &&
      message.sender === "mikata" &&
      message.cardType !== ChatCardTypes.USER_RESPONSE
    );
  });
};
