import { useContext, useEffect, useState } from "react";
import { PatientConsentStatus } from "../../../../types";
import { SocketMessageType, WebSocketContext } from "../../../providers/WebSocketProvider";

const useWebSocketConnection = () => {
  const { connected, recording, sendMessage } = useContext(WebSocketContext);
  const [patientConsentLoading, setPatientConsentLoading] = useState(false);

  // Reset patientConsentLoading when patientConsentStatus changes
  useEffect(() => {
    setPatientConsentLoading(false);
  }, [recording?.patientConsentStatus]);

  // Reset patientConsentLoading when "reconnecting" in a loading state
  useEffect(() => {
    if (connected && patientConsentLoading) {
      setPatientConsentLoading(false);
    }
  }, [connected]);

  const acceptPatientConsent = ({ patientSignature }: { patientSignature: string }) => {
    if (sendMessage) {
      setPatientConsentLoading(true);
      sendMessage(SocketMessageType.updatePatientConsentStatus, {
        patientConsentStatus: PatientConsentStatus.ACCEPTED,
        patientSignature
      });
    }
  };
  const denyPatientConsent = () => {
    if (sendMessage) {
      setPatientConsentLoading(true);
      sendMessage(SocketMessageType.updatePatientConsentStatus, {
        patientConsentStatus: PatientConsentStatus.DENIED
      });
    }
  };
  const editPatientConsent = () => {
    if (sendMessage) {
      setPatientConsentLoading(true);
      sendMessage(SocketMessageType.updatePatientConsentStatus, {
        patientConsentStatus: PatientConsentStatus.PENDING
      });
    }
  };

  const endScribeSession = () => {
    if (sendMessage) {
      sendMessage(SocketMessageType.endSession);
    }
  };

  return {
    connected,
    recording,
    patientConsentLoading,
    acceptPatientConsent,
    denyPatientConsent,
    editPatientConsent,
    endScribeSession
  };
};

export default useWebSocketConnection;
