import React from "react";

type PropsType = {
  size?: number;
};

const Star = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0767 4.22025C11.4182 3.39891 12.5818 3.39891 12.9233 4.22026L14.4105 7.79625C14.5545 8.1425 14.8801 8.37909 15.2539 8.40906L19.1142 8.71858C20.0008 8.78967 20.3604 9.89611 19.6849 10.4748L16.7436 12.9947C16.4588 13.2386 16.3345 13.6214 16.4214 13.9861L17.32 17.7537C17.5264 18.619 16.5851 19.3028 15.826 18.8391L12.5213 16.8203C12.2013 16.6248 11.7987 16.6248 11.4787 16.8203L8.17398 18.8391C7.4149 19.3028 6.4736 18.619 6.67996 17.7537L7.57856 13.9861C7.66555 13.6214 7.54119 13.2386 7.25645 12.9947L4.3151 10.4748C3.63963 9.89611 3.99916 8.78967 4.88578 8.71858L8.7461 8.40906C9.1199 8.37909 9.44552 8.1425 9.58951 7.79625L11.0767 4.22025Z"
        fill="#FFD440"
      />
    </svg>
  );
};

export default Star;
