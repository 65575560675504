import React from "react";
import { connect } from "react-redux";

import Button from "../Button/Button";

import { fetchNextMessage as fetchNextMessageAction } from "../../../actions";

import { ChatFlowControlFlags } from "../../../types";

import moment from "moment";

interface Props {
  index: number;
  message: Message;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
}

const SkipButton = ({ index, message, fetchNextMessage }: Props) => {
  const payloadOptions = message.payloadOptions as BasePayloadOptions;
  const skipMessage = payloadOptions?.skipMessage || "Skip";
  const userResponse = { text: skipMessage, value: ChatFlowControlFlags.SKIP };

  const onSkip = () => {
    fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      chatFlowId: null,
      showUserResponse: true,
      userResponseType: message.cardType,
      createdAt: moment().toDate()
    });
  };
  return (
    <Button id={`skipBtn-${index}`} type="button" variant="text" onClick={onSkip} disableOnClick>
      {skipMessage}
    </Button>
  );
};

export default connect(null, {
  fetchNextMessage: fetchNextMessageAction
})(SkipButton);
