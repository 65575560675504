import { SEND_REFRESH_LINK } from "../constants";
import { SendRefreshLinkAction } from "../types/actions";
import { ActionStatus } from "../types";

const initialState = {
  sendRefreshLinkError: false,
  sendRefreshLinkLoading: false
};

export const messagesReducer = (
  state = initialState,
  action: SendRefreshLinkAction
): MessagesStore => {
  const { type } = action;
  switch (type) {
    case SEND_REFRESH_LINK: {
      const { status } = action as SendRefreshLinkAction;
      return {
        ...state,
        sendRefreshLinkError: status === ActionStatus.error,
        sendRefreshLinkLoading: status === ActionStatus.loading
      };
    }
    default:
      return state;
  }
};
