/* Icons
Important: make sure these are valid JSX tags
========================================= */

import React from "react";

import icons from "../default/icons";

const cigIcons = {
  ...icons,
  logo: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="650"
      height="650"
      viewBox="-2015 -2000 4030 4030"
    >
      <title>maple leaf from the flag of Canada</title>
      <path
        fill="#f00"
        d="m-90 2030 45-863a95 95 0 0 0-111-98l-859 151 116-320a65 65 0 0 0-20-73l-941-762 212-99a65 65 0 0 0 34-79l-186-572 542 115a65 65 0 0 0 73-38l105-247 423 454a65 65 0 0 0 111-57l-204-1052 327 189a65 65 0 0 0 91-27l332-652 332 652a65 65 0 0 0 91 27l327-189-204 1052a65 65 0 0 0 111 57l423-454 105 247a65 65 0 0 0 73 38l542-115-186 572a65 65 0 0 0 34 79l212 99-941 762a65 65 0 0 0-20 73l116 320-859-151a95 95 0 0 0-111 98l45 863z"
      />
    </svg>
  ),
  headerIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="650"
      height="650"
      viewBox="-2015 -2000 4030 4030"
    >
      <title>maple leaf from the flag of Canada</title>
      <path
        fill="#f00"
        d="m-90 2030 45-863a95 95 0 0 0-111-98l-859 151 116-320a65 65 0 0 0-20-73l-941-762 212-99a65 65 0 0 0 34-79l-186-572 542 115a65 65 0 0 0 73-38l105-247 423 454a65 65 0 0 0 111-57l-204-1052 327 189a65 65 0 0 0 91-27l332-652 332 652a65 65 0 0 0 91 27l327-189-204 1052a65 65 0 0 0 111 57l423-454 105 247a65 65 0 0 0 73 38l542-115-186 572a65 65 0 0 0 34 79l212 99-941 762a65 65 0 0 0-20 73l116 320-859-151a95 95 0 0 0-111 98l45 863z"
      />
    </svg>
  )
};

export default cigIcons;

export type IconTypes = keyof typeof cigIcons;
