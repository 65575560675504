import React from "react";

const LinkResent: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="292"
      height="165"
      viewBox="0 0 292 165"
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          stroke="#1D2029"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.8"
          d="M105.521 77.05 93.706 80.3M88.78 103.905l-14.844.027-7.216 11.144"
        />
        <path
          fill="#AB96E8"
          d="M75.267 109.034c-1.953 2.066-2.114 5.245-.346 7.427a69.01 69.01 0 0 0 7.395 7.813c28.548 25.832 73.279 23.281 99.423-5.628 25.741-28.458 24.407-72.15-3.28-98.317a69.108 69.108 0 0 0-7.074-5.852c-2.29-1.654-5.476-1.348-7.441.73l-88.677 93.827Z"
        />
        <circle
          cx="143.912"
          cy="60.992"
          r="13.2"
          fill="#fff"
          transform="rotate(-46.616 143.912 60.992)"
        />
        <circle
          cx="173.822"
          cy="80.444"
          r="13.2"
          fill="#fff"
          transform="rotate(-46.616 173.822 80.444)"
        />
        <circle
          cx="139.663"
          cy="63.625"
          r="8.4"
          fill="#1D2029"
          transform="rotate(-46.616 139.663 63.625)"
        />
        <circle
          cx="169.431"
          cy="82.382"
          r="8.4"
          fill="#1D2029"
          transform="rotate(-46.616 169.431 82.382)"
        />
        <path
          stroke="#1D2029"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.8"
          d="m163.453 118.614 5.896 10.321-.662 10.411M105.929 120.798l-13.643 3.946-5.07 12.701"
        />
        <path
          fill="#1D2029"
          d="m104.707 57.949 14.857 7.46a7.2 7.2 0 0 1 3.871 7.62l-4.89 29.28a2.4 2.4 0 0 1-3.544 1.696l-16.15-9.093a4.8 4.8 0 0 1-2.386-4.934l4.795-30.26a2.4 2.4 0 0 1 3.447-1.77Z"
        />
        <path
          stroke="#1D2029"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.8"
          d="m93.706 80.3 4.492 6.788"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h292v165H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkResent;
