import findLastIndex from "lodash/findLastIndex";
import {
  FETCH_NEXT_MESSAGE,
  CLEAR_CHAT_MESSAGES,
  ADD_USER_RESPONSE,
  UNDO_USER_RESPONSE,
  DELETE_SECURE_MESSAGE_ITEM,
  START_CHAT_SESSION
} from "../constants";

import {
  FetchNextMessageAction,
  ClearChatMessagesAction,
  AddUserResponseAction,
  UndoUserResponseAction,
  DeleteSecureMessageItemAction,
  StartChatSessionAction
} from "../actions/chatMessages";
import { ActionStatus } from "../types";

export type ChatMessagesAction =
  | FetchNextMessageAction
  | ClearChatMessagesAction
  | AddUserResponseAction
  | UndoUserResponseAction
  | DeleteSecureMessageItemAction
  | StartChatSessionAction;

export type ChatMessagesReduxState = {
  conversationId: null | number;
  sessionId: null | number;
  loading: boolean;
  messages: Message[];
  breadcrumb?: Breadcrumb[]; // Deprecated Chat System - needed for instructions
};

const initialState: ChatMessagesReduxState = {
  conversationId: null,
  sessionId: null,
  loading: false,
  messages: [],
  breadcrumb: []
};

export const chatMessagesReducer = (
  state = initialState,
  action: ChatMessagesAction
): ChatMessagesReduxState => {
  const { type } = action;

  switch (type) {
    case CLEAR_CHAT_MESSAGES: {
      return {
        ...initialState
      };
    }
    case DELETE_SECURE_MESSAGE_ITEM: {
      const { status, payload } = action as DeleteSecureMessageItemAction;

      const { messageTemplateId, secureMessageItemId } = payload || {};
      const newMessages =
        status === ActionStatus.success && messageTemplateId && secureMessageItemId
          ? [...state.messages].map((message) => {
              if (message.id !== messageTemplateId) return message;
              const nextItems = [
                ...((message.payloadOptions as SecureMessagePayloadOptions).secureMessageItems ||
                  [])
              ].filter((item) => item.id !== secureMessageItemId);

              return {
                ...message,
                payloadOptions: {
                  ...message.payloadOptions,
                  secureMessageItems: nextItems
                }
              };
            })
          : [...state.messages];

      return {
        ...state,
        messages: newMessages
      };
    }
    case ADD_USER_RESPONSE: {
      const { payload } = action as AddUserResponseAction;

      return {
        ...state,
        messages: [...state.messages, payload.message]
      };
    }
    case UNDO_USER_RESPONSE: {
      const { status, payload } = action as UndoUserResponseAction;

      const lastIndexOfUndoMessage =
        status === ActionStatus.success && payload?.undoToMessageTemplateId
          ? findLastIndex(state.messages, (msg) => {
              return msg.id === payload?.undoToMessageTemplateId;
            })
          : null;
      // Note index 0, first message, is a valid index to undo to.
      const foundUndoMessage = lastIndexOfUndoMessage !== null && lastIndexOfUndoMessage >= 0;
      const newMessages = foundUndoMessage
        ? [...state.messages].splice(0, lastIndexOfUndoMessage + 1)
        : [...state.messages];

      return {
        ...state,
        loading: status === ActionStatus.loading,
        messages: newMessages
      };
    }
    case FETCH_NEXT_MESSAGE: {
      const {
        status,
        isFetchingNext = false,
        updateExistingMessage = false,
        payload
      } = action as FetchNextMessageAction;

      let newMessages = state.messages;
      const newBreadcrumb =
        status === ActionStatus.success && payload ? payload.breadcrumb : state.breadcrumb;

      if (updateExistingMessage && status === ActionStatus.success && payload) {
        const lastIndexOfMessage = findLastIndex(newMessages, (msg) => {
          return msg.id === payload?.message.id;
        });

        newMessages = state.messages.map((message, idx) => {
          if (idx === lastIndexOfMessage) {
            return payload.message;
          }
          return message;
        });
      } else if (status === ActionStatus.success && payload) {
        newMessages = [...state.messages, payload.message];
      }

      return {
        ...state,
        loading: status === ActionStatus.loading || isFetchingNext,
        messages: newMessages,
        breadcrumb: newBreadcrumb
      };
    }
    case START_CHAT_SESSION: {
      const { payload } = action as StartChatSessionAction;

      return {
        ...state,
        conversationId: payload?.conversationId || null,
        sessionId: payload?.sessionId || null
      };
    }

    default:
      return state;
  }
};
