export const userInputHasEnoughDigits = (dateString: string, format: string) => {
  const charactersMatches = format?.match(/[A-Za-z]/g);
  const charactersMatchLength = charactersMatches ? charactersMatches.length : 0;

  const numberMatches = dateString?.match(/\d/g);
  const matchedLength = numberMatches ? numberMatches.length : 0;

  return matchedLength === charactersMatchLength;
};

export const isValidDateFormat = (dateString: string, format: string) => {
  if (!userInputHasEnoughDigits(dateString, format)) return true;

  const formatParts = format.split("-");
  const dateParts = dateString.split("-");

  const yearIndex = formatParts.indexOf("YYYY");
  const monthIndex = formatParts.indexOf("MM");
  const dayIndex = formatParts.indexOf("DD");

  const year = Number(dateParts[yearIndex]);
  const month = Number(dateParts[monthIndex]);

  // Handle "YYYY-MM" and "YYYY-MM-DD" cases
  const isValidDate = (year: number, month: number, day = 1) => {
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  };

  // If the day is present, validate full date, otherwise validate month and year
  return dayIndex >= 0
    ? isValidDate(year, month, Number(dateParts[dayIndex]))
    : isValidDate(year, month);
};
