/* Theme > colors
========================================= */

const colors = {
  white: "#FFFFFF",
  black: "#1D2029",
  sandyBeach: "#ffebcc",
  sidecar: "#F2DDBA",
  primary: {
    blue: "#4072DE",
    dark: "#1D263C"
  },
  secondary: {
    blue: "#1A4B74",
    peach: "#FFEBD3",
    green: "#679186",
    pink: "#FFB4AC"
  },
  grey: [
    "#FAFAF9", // 0 lightest
    "#F2F2F3", // 1
    "#D7D8DB", // 2
    "#94979E", // 3
    "#494B50", // 4 text
    "#1D2029" // 5 darkest
  ],
  error: {
    text: "#A31D18",
    border: "#D9605E",
    background: "#FFE9EB"
  },
  warning: {
    text: "#8F4D16",
    border: "#FFCDA3",
    background: "#FFCDA3"
  },
  success: {
    text: "#385A51",
    border: "#54C08A",
    background: "#54C08A",
    label: "#2D7953"
  },
  bg: {
    blue: "#F1F7FD",
    green: "#DEEDE8"
  },
  icon: {
    primary: "#4072DE"
  }
};
export default colors;
