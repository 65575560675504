import React from "react";

const LinkExpired: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="292"
      height="165"
      viewBox="0 0 292 165"
      fill="none"
    >
      <path
        stroke="#1D2029"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.8"
        d="m79 120.836 16.28 1.418 13.698-8.313"
      />
      <path
        fill="#1D2029"
        d="m58.013 87.658 16.606.79a7.2 7.2 0 0 1 6.63 5.395l7.409 28.746a2.4 2.4 0 0 1-2.552 2.988l-18.45-1.759a4.8 4.8 0 0 1-4.182-3.541L55.58 90.673a2.4 2.4 0 0 1 2.433-3.015Z"
      />
      <path
        fill="#AB96E8"
        d="M228.44 119.734c2.843 0 5.264-2.066 5.636-4.849.471-3.504.68-7.092.598-10.742-.835-38.491-33.414-69.248-72.383-68.391-38.363.84-69.2 31.82-69.2 69.915 0 3.105.205 6.162.606 9.162.371 2.801 2.782 4.905 5.642 4.905H228.44Z"
      />
      <path
        stroke="#1D2029"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.8"
        d="m144.068 128.843 16.142-2.545 9.959-15.79M233.491 125.734h-20.4l5.16-17.859"
      />
      <circle cx="114.691" cy="95.735" r="14.4" fill="#fff" />
      <circle cx="153.668" cy="95.735" r="14.4" fill="#fff" />
      <circle cx="109.987" cy="96.935" r="9.6" fill="#1D2029" />
      <circle cx="148.964" cy="96.935" r="9.6" fill="#1D2029" />
      <path
        fill="#1D2029"
        fillRule="evenodd"
        d="M63.114 45.203c4.109-.663 8.238.314 11.615 2.752a15.497 15.497 0 0 1 6.268 10.16 15.502 15.502 0 0 1-2.753 11.615 15.499 15.499 0 0 1-10.16 6.267c-.84.136-1.68.203-2.517.203a15.473 15.473 0 0 1-9.1-2.956A15.498 15.498 0 0 1 50.2 63.085c-1.37-8.491 4.421-16.512 12.913-17.882ZM51.166 62.929Zm14.215-14.29c-.562.01-1.125.06-1.688.153h-.003c-6.51 1.05-10.954 7.206-9.901 13.715a11.87 11.87 0 0 0 4.806 7.79 11.865 11.865 0 0 0 8.907 2.111 11.872 11.872 0 0 0 7.792-4.806 11.874 11.874 0 0 0 2.11-8.907 11.869 11.869 0 0 0-4.806-7.79 11.87 11.87 0 0 0-6.974-2.267h-.243Z"
        clipRule="evenodd"
      />
      <path
        fill="#1D2029"
        fillRule="evenodd"
        d="M65.593 51.48a2.153 2.153 0 0 0-2.153 2.153v7.613a2.153 2.153 0 0 0 4.306 0v-7.613a2.153 2.153 0 0 0-2.153-2.153ZM64.07 65.932a2.153 2.153 0 1 0 3.045 3.044 2.153 2.153 0 0 0-3.045-3.044Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default LinkExpired;
