export enum RecordingSessionStatus {
  "PENDING" = "pending",
  "RECORDING" = "recording",
  "COMPLETE" = "complete",
  "PAUSED" = "paused"
}

export enum PatientConsentStatus {
  "PENDING" = "pending",
  "ACCEPTED" = "accepted",
  "DENIED" = "denied"
}

export type RecordingState = {
  status: RecordingSessionStatus;
  startedAt: Date | null;
  providerReady: boolean;
  patientConsentStatus: PatientConsentStatus;
  sessionCompleted: boolean;
};
