import React from "react";
import { Important as ImportantComponent } from "../../ui";

import { ImportantNode } from "../../../types";

type PropsType = {
  node: ImportantNode;
};

const Important = ({ node }: PropsType) => {
  return (
    <ImportantComponent title={node.properties.title}>{node.properties.details}</ImportantComponent>
  );
};

export default Important;
