import { SHOW_NOTIFICATION_OVERLAY, CLEAR_NOTIFICATION_OVERLAY } from "../constants";
import { ShowNotificationOverlayAction, NotificationOverlayActions } from "../types/actions";

const initialState: NotificationOverlayStore = {
  visible: false,
  type: null,
  meta: {}
};

export const notificationOverlayReducer = (
  state = initialState,
  action: NotificationOverlayActions
): NotificationOverlayStore => {
  switch (action.type) {
    case SHOW_NOTIFICATION_OVERLAY: {
      const { payload } = action as ShowNotificationOverlayAction;

      return {
        ...state,
        ...payload,
        visible: true
      };
    }
    case CLEAR_NOTIFICATION_OVERLAY: {
      return initialState;
    }
    default:
      return state;
  }
};
