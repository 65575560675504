/* Date Input
======================================= */

import React, { useState } from "react";
import DatePicker from "react-date-picker";
import {
  Calendar,
  ChevronLeft,
  ChevronRight,
  Close,
  DoubleChevronLeft,
  DoubleChevronRight
} from "../../Icons";
import styles from "./index.module.scss";
import "./custom.scss";

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  name?: string;
  updateResponse?: (userResponse: string) => void;
  value?: string;
  defaultToToday?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

/* Component
--------------------------------------- */

const DateSelector: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const collection = document.getElementsByTagName("input");
  const inputArray = Array.from(collection);

  inputArray.map((element) => {
    // Prevents mobile keyboard from opening and obstructing the calendar component from view.
    element.readOnly = true;
    element.inputMode = "none";
    return element;
  });

  return (
    <>
      {isOpen && (
        <div
          className={styles.DatePickerOverlay}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      )}
      <DatePicker
        id={props.id}
        name={props.name}
        value={
          props.value
            ? new Date(
                Number(props.value.slice(0, 4)), // YYYY
                Number(props.value.slice(5, 7)) - 1, // MM, minus 1 to account for month index
                Number(props.value.slice(8, 10)) // DD
              )
            : ""
        }
        calendarIcon={<Calendar />}
        clearIcon={<Close />}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        format="y-MM-dd"
        onCalendarOpen={() => {
          setIsOpen(true);
        }}
        onCalendarClose={() => {
          setIsOpen(false);
        }}
        onChange={(value) => {
          const selectedDate = (value as Date)?.toISOString().slice(0, 10); // YYYY-MM-DD

          if (props.updateResponse) {
            props.updateResponse(selectedDate);
          }
        }}
        calendarProps={{
          calendarType: "gregory",
          nextLabel: <ChevronRight size={14} />,
          next2Label: <DoubleChevronRight size={14} />,
          prevLabel: <ChevronLeft size={14} />,
          prev2Label: <DoubleChevronLeft size={10} />,
          minDate: props.minDate ? props.minDate : new Date(1901, 0, 1),
          maxDate: props.maxDate ? props.maxDate : new Date(2100, 11, 31),
          defaultView: "century",
          defaultValue: props.defaultToToday ? new Date() : new Date(1901, 0, 1)
        }}
      />
    </>
  );
};

export default DateSelector;
