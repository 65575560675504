import React from "react";

const DownloadIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.013"
      height="20.012"
      viewBox="0 0 24.013 20.012"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Combined_Shape"
            data-name="Combined Shape"
            d="M11.392,19.8h0l-.017-.014-.006-.005-.011-.009-.015-.013,0,0-.042-.04-4-3.995A1,1,0,0,1,8.716,14.3L11.009,16.6V10.012a1,1,0,0,1,2,0V16.6L15.3,14.3a1,1,0,0,1,1.415,1.415l-4,4a1,1,0,0,1-1.324.079Zm8.679-3.121a1,1,0,0,1,.243-1.393,4,4,0,0,0-2.3-7.272h-1.26a1,1,0,0,1-.968-.751A7,7,0,1,0,3.758,13.639a1,1,0,0,1-1.5,1.325A9,9,0,1,1,17.49,6.012h.518A6,6,0,0,1,21.464,16.92a1,1,0,0,1-1.393-.243Z"
            fill="#494b50"
          />
        </clipPath>
      </defs>
      <g id="Icons_download-cloud" data-name="Icons/download-cloud" transform="translate(0 -2)">
        <g id="Group_46" data-name="Group 46" transform="translate(0 2)">
          <path
            id="Combined_Shape-2"
            data-name="Combined Shape"
            d="M11.392,19.8h0l-.017-.014-.006-.005-.011-.009-.015-.013,0,0-.042-.04-4-3.995A1,1,0,0,1,8.716,14.3L11.009,16.6V10.012a1,1,0,0,1,2,0V16.6L15.3,14.3a1,1,0,0,1,1.415,1.415l-4,4a1,1,0,0,1-1.324.079Zm8.679-3.121a1,1,0,0,1,.243-1.393,4,4,0,0,0-2.3-7.272h-1.26a1,1,0,0,1-.968-.751A7,7,0,1,0,3.758,13.639a1,1,0,0,1-1.5,1.325A9,9,0,1,1,17.49,6.012h.518A6,6,0,0,1,21.464,16.92a1,1,0,0,1-1.393-.243Z"
            fill="#494b50"
          />
        </g>
      </g>
    </svg>
  );
};

export default DownloadIcon;
