import axios from "axios";

import { getLastReceivedMessage, getLastWithResponseByMessageId } from "../utils";
import {
  ROOT_URL,
  FETCH_NEXT_MESSAGE,
  CLEAR_CHAT_MESSAGES,
  ADD_USER_RESPONSE,
  UNDO_USER_RESPONSE,
  DELETE_SECURE_MESSAGE_ITEM,
  NotificationTypes,
  ChatCardTypes,
  START_CHAT_SESSION
} from "../constants";

import { showNotificationOverlay } from "./notifications-overlay";
import { isTextValueObject } from "../types/typeguards";

import { Dispatch, ActionStatus, ChatFlowResponseType } from "../types";

export type ClearChatMessagesAction = {
  type: typeof CLEAR_CHAT_MESSAGES;
};

export const clearChatMessages = () => async (dispatch: Dispatch) => {
  return dispatch({ type: CLEAR_CHAT_MESSAGES });
};

export type AddUserResponseAction = {
  type: typeof ADD_USER_RESPONSE;
  payload: { message: Message };
};

type AddUserResponseData = {
  userResponse: UserResponse;
  userResponseType: string; // TODO: Make better type definition throughout app ChatFlowResponseType | ChatCardTypes;
  messageTemplateId: number;
  chatFlowMessageId?: number; // Deprecated chat system
};

const addUserResponse =
  ({
    userResponse,
    userResponseType,
    messageTemplateId,
    chatFlowMessageId = 0 // Deprecated chat system
  }: AddUserResponseData) =>
  async (dispatch: Dispatch) => {
    let payloadContent: Message["payloadContent"] = "";

    if (
      (userResponse as PatientVerificationResponse)?.responseType ===
      ChatFlowResponseType.PATIENT_VERIFICATION_ATTEMPT
    ) {
      payloadContent = "Identity verified";
    } else if (isTextValueObject(userResponse) && userResponseType !== ChatCardTypes.SCALE_INPUT) {
      payloadContent = userResponse.text;
    } else {
      payloadContent = userResponse;
    }

    const userResponseMessage: Message = {
      cardType: ChatCardTypes.USER_RESPONSE,
      sender: "user",
      payloadContent,
      messageTemplateId,
      chatFlowMessageId,
      createdAt: new Date().toISOString(),
      userResponseType
    };
    return dispatch({ type: ADD_USER_RESPONSE, payload: { message: userResponseMessage } });
  };

export type UndoUserResponseAction = {
  type: typeof UNDO_USER_RESPONSE;
  status: ActionStatus;
  payload?: {
    undoToMessageTemplateId: number;
  };
};

export type UndoUserResponseData = {
  messageTemplateId: number;
};

export const undoUserResponse =
  ({ messageTemplateId }: UndoUserResponseData) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    const { chatMessages } = getState();
    const { conversationId, sessionId, messages } = chatMessages;

    dispatch({
      type: UNDO_USER_RESPONSE,
      status: ActionStatus.loading
    });
    const sessionToken = sessionStorage.getItem("token");

    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" }
    };

    // Deprecated Chat System - Find chatFlowMessageId of messageTemplateId
    const lastResponseMessage = getLastWithResponseByMessageId(messages, messageTemplateId);
    const chatFlowMessageId = lastResponseMessage?.chatFlowMessageId || null;

    try {
      const response = await axios.patch(
        `${ROOT_URL}/conversations/${conversationId}/sessions/${sessionId}`,
        { chatFlowMessageId, messageTemplateId },
        config
      );
      const { data } = response;

      if (!data?.success) {
        return dispatch({
          type: UNDO_USER_RESPONSE,
          status: ActionStatus.error
        });
      }

      return dispatch({
        type: UNDO_USER_RESPONSE,
        status: ActionStatus.success,
        payload: { undoToMessageTemplateId: messageTemplateId }
      });
    } catch (e) {
      return dispatch({
        type: UNDO_USER_RESPONSE,
        status: ActionStatus.error
      });
    }
  };

export type StartChatSessionActionData = {
  conversationId?: number;
  sessionId?: number;
};

export type StartChatSessionAction = {
  type: typeof START_CHAT_SESSION;
  payload?: StartChatSessionActionData;
};

export const startChatSession =
  ({ conversationId, sessionId }: StartChatSessionActionData) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: START_CHAT_SESSION,
      payload: {
        conversationId,
        sessionId
      }
    });
  };

export type FetchNextMessageAction = {
  type: typeof FETCH_NEXT_MESSAGE;
  status: ActionStatus;
  isFetchingNext?: boolean;
  updateExistingMessage?: boolean;
  payload?: {
    conversationId: number;
    message: Message;
    breadcrumb: Breadcrumb[]; // Needed for deprecated chat instructions card
  };
};

export type FetchNextMessageResponse = {
  data: {
    message: Message;
    chatFlowMessageId?: number;
    breadcrumb: Breadcrumb[];
    sessionId?: number;
  };
};

const CHAT_MESSAGE_DELAY_MILLISECONDS =
  parseInt(`${process.env.REACT_APP_CHAT_MESSAGE_DELAY}`, 10) || 2000;

export const fetchNextMessage =
  (params: FetchNextMessageParams) =>
  // eslint-disable-next-line consistent-return
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    // Resolve messageTemplateId and sessionId context
    const { chatInfo, chatMessages } = getState();
    const { conversationId } = chatMessages;
    const startTime = Date.now();
    const sessionToken = sessionStorage.getItem("token");

    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" }
    };

    dispatch({
      type: FETCH_NEXT_MESSAGE,
      status: ActionStatus.loading
    });

    const fromMessageTemplateId = params.messageTemplateId
      ? params.messageTemplateId
      : getLastReceivedMessage(chatMessages.messages)?.id;

    const data: ChatMessageInfoActionData = {
      messageTemplateId: fromMessageTemplateId,
      userResponse: params.userResponse || null,
      sessionId: chatMessages.sessionId || null
    };

    try {
      const payload: FetchNextMessageResponse = await axios.post(
        `${ROOT_URL}/conversations/${conversationId}/get-next-message`,
        data,
        config
      );
      const nextMessage = payload?.data?.message;

      if (!nextMessage) {
        return dispatch({
          type: FETCH_NEXT_MESSAGE,
          status: ActionStatus.error
        });
      }

      // Wait for remaining time in min chat messages chat delay before adding message and fetching next
      const timeElapsed = Date.now() - startTime;
      const timeLeftInDelay = CHAT_MESSAGE_DELAY_MILLISECONDS - timeElapsed;
      if (timeLeftInDelay > 0) {
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            return resolve();
          }, timeLeftInDelay);
        });
      }

      // TODO: move patient verification to its own action?
      if (nextMessage.cardType === ChatCardTypes.PATIENT_VERIFICATION && nextMessage.failed) {
        return dispatch({
          type: FETCH_NEXT_MESSAGE,
          status: ActionStatus.success,
          isFetchingNext: false,
          updateExistingMessage: true,
          payload: {
            conversationId,
            message: { ...nextMessage, chatFlowMessageId: payload?.data?.chatFlowMessageId },
            breadcrumb: payload.data.breadcrumb
          }
        });
      }

      // Add User Response Message
      if (
        params.showUserResponse &&
        params.userResponse &&
        params.userResponseType &&
        fromMessageTemplateId
      ) {
        addUserResponse({
          userResponse: params.userResponse,
          userResponseType: params.userResponseType as ChatFlowResponseType,
          messageTemplateId: fromMessageTemplateId
        })(dispatch);
      }

      const shouldFetchNextMessage =
        nextMessage &&
        !nextMessage.expectsResponse &&
        nextMessage.cardType !== ChatCardTypes.END &&
        nextMessage.cardType !== ChatCardTypes.FLAGGED_END &&
        nextMessage.cardType !== ChatCardTypes.DEAD_END;

      if (!chatInfo.sessionId && !chatMessages.sessionId && payload?.data?.sessionId) {
        startChatSession({ conversationId, sessionId: payload.data.sessionId })(dispatch);
      }

      dispatch({
        type: FETCH_NEXT_MESSAGE,
        status: ActionStatus.success,
        isFetchingNext: shouldFetchNextMessage,
        payload: {
          conversationId,
          message: { ...nextMessage, chatFlowMessageId: payload?.data?.chatFlowMessageId },
          breadcrumb: payload.data.breadcrumb
        }
      });

      if (shouldFetchNextMessage) {
        fetchNextMessage({
          messageTemplateId: nextMessage.id,
          userResponse: null,
          sessionId: params.sessionId,
          lastReceivedMessageId: payload?.data?.chatFlowMessageId || null,
          chatFlowId: null
        })(dispatch, getState);
      }
    } catch (error: any) {
      dispatch({
        type: FETCH_NEXT_MESSAGE,
        status: ActionStatus.error
      });

      if (error?.response?.data.type === "PATIENT_VERIFICATION_MAX_AUTH_ATTEMPTS") {
        return dispatch(showNotificationOverlay(NotificationTypes.ACCESS_BLOCKED));
      }

      return dispatch(showNotificationOverlay(NotificationTypes.GENERIC_APPLICATION_ERROR));
    }
  };

export const startConversation =
  (conversationId: number, messageTemplateId: number) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    // set conversationId context
    dispatch({
      type: START_CHAT_SESSION,
      payload: {
        conversationId,
        sessionId: undefined
      }
    });

    const { chatInfo } = getState();

    // fetchNextMessage
    return fetchNextMessage({
      messageTemplateId,
      userResponse: null,
      sessionId: chatInfo?.sessionId || undefined,
      lastReceivedMessageId: null,
      chatFlowId: null
    })(dispatch, getState);
  };

// Custom Chat Card Specific Message Actions

export type DeleteSecureMessageItemAction = {
  type: typeof DELETE_SECURE_MESSAGE_ITEM;
  status: ActionStatus;
  payload?: {
    messageTemplateId: number;
    secureMessageItemId: number;
  };
};

export type DeleteSecureMessageItemResponse = {
  data: { success: boolean };
};
export const deleteSecureMessageItem =
  (messageTemplateId: number, secureMessageItemId: number) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    const sessionToken = sessionStorage.getItem("token");
    const { chatMessages } = getState();
    const { conversationId } = chatMessages;

    const config = {
      headers: { Authorization: sessionToken, "Content-Type": "application/json" }
    };

    try {
      const response: DeleteSecureMessageItemResponse = await axios.delete(
        `${ROOT_URL}/conversations/${conversationId}/secure-message-items/${secureMessageItemId}`,
        config
      );

      if (response.data.success) {
        dispatch({
          type: DELETE_SECURE_MESSAGE_ITEM,
          status: ActionStatus.success,
          payload: {
            messageTemplateId,
            secureMessageItemId
          }
        });
      }
    } catch (e) {
      dispatch(showNotificationOverlay());
    }
  };
