import React, { createContext, useContext, useEffect, useMemo } from "react";

const ThemeContext = createContext({ secondaryColour: "#000000" });

export const useTheme = () => useContext(ThemeContext);

const ThemeProvider = ({
  secondaryColour,
  children
}: {
  secondaryColour: string;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    document.documentElement.style.setProperty("--secondary-color", secondaryColour || "#000000");
  }, [secondaryColour]);

  const theme = useMemo(
    () => ({ secondaryColour: secondaryColour || "#000000" }),
    [secondaryColour]
  );

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
