import React from "react";

type PropsType = {
  size?: number;
};

const DoubleChevronRight = ({ size = 16 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m6.724 8-3.529 3.528a.667.667 0 1 0 .943.943l4-4a.667.667 0 0 0 0-.942l-4-4a.667.667 0 1 0-.943.942L6.724 8Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m11.39 8-3.528 3.528a.667.667 0 1 0 .943.943l4-4a.667.667 0 0 0 0-.942l-4-4a.667.667 0 1 0-.943.942L11.391 8Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default DoubleChevronRight;
