import { ClearNotificationOverlayAction, ShowNotificationOverlayAction } from "../types";
import { NotificationType } from "../types/notifications";

import {
  CLEAR_NOTIFICATION_OVERLAY,
  NotificationTypes,
  SHOW_NOTIFICATION_OVERLAY
} from "../constants";

export const showNotificationOverlay = (
  notificationType: NotificationType = NotificationTypes.GENERIC_APPLICATION_ERROR
): ShowNotificationOverlayAction => ({
  type: SHOW_NOTIFICATION_OVERLAY,
  payload: {
    type: notificationType
  }
});

export const clearNotificationOverlay = (): ClearNotificationOverlayAction => ({
  type: CLEAR_NOTIFICATION_OVERLAY
});
